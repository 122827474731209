import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import Loader from '../../components/Loader/loader';
import PaginationComponent from '../../PaginationComponent';
import { GetPermission, getApi, postApi } from '../service/lenderService';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Image } from 'antd';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import Swal from 'sweetalert2';
import Moment from 'react-moment';

const ManageEwaAdvance = () => {
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const navigate = useNavigate();
    const CloseModal = useRef()
    const fileInputRef = useRef(null);
    const tableRef = useRef(null);
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [notificationCount, setNotificationCount] = useState(0)
    const [totalItems, settotalItems] = useState(false);
    const [checkbox, setCheckbox] = useState(false)
    const [search, setSearch] = useState('');
    const [refresh, setRefresh] = useState(true)
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const [imageUpload, setimageUpload] = useState(null)
    const [docUpload, setDocUpload] = useState([]);
    const [userloanID, setuserloanID] = useState()
    const [username, setusername] = useState()
    const [disburseID, setdisburseID] = useState('')
    const [dataArray, setDataArray] = useState([]);
    const [chatMsgNotification, setChatMsgNotification] = useState([])
    const [data, setData] = useState([]);
    const [transactionPrimaryId, setTransactionPrimaryId] = useState([]);
    const [isPinUpdated, setIsPinUpdated] = useState()
    const [file, setFile] = useState(null);
    const [getUserLoanID, setGetUserLoanID] = useState('')

    const ViewLoanCases = async (token) => {
        // const response = await GetLoanCasesDetail(token, search, pagesCount, sortedBy, orderBy)
        const response = await getApi("/get-loan-application", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })

        // console.log(response)
        if (response?.status == true) {
            setTimeout(() => {
                setDataArray(response?.data?.rows)
                setTotalPagesCount(response?.data?.count)
                setIsPinUpdated(response?.isPinUpdated)
                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response?.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response?.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response?.message)
            navigate('/lender/dashboard')
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        ViewLoanCases()

    }, [search, pagesCount, refresh, sortedBy, orderBy])


    const getBorrowerdetail = async (id, name) => {
        // const response = await GetBorrowerChatdetail(token, id)
        const response = await getApi(`/get-loan-chat/${id}`)
        setuserloanID(id)
        setusername(name)
        // console.log(response.data)
        if (response.status == true) {
            // setTimeout(() => {
            setData(response.data)
            // setloader1(false)
            // }, 2000);
            // setloader1(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        } else {
            console.log(response)
        }
    }


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // ViewInvestment(token, search, pagesCount )
    }
    const copiedInfo = () => {
        toast.success("Account No. copied Successfully")
    }



    const selectTransactions = async (id, e) => {
        // settotalItems(true)
        if (!transactionPrimaryId.includes(id)) {
            setTransactionPrimaryId(oldArr => [...oldArr, id]);
            setCheckbox(true)
            settotalItems()

        } else {
            let x = transactionPrimaryId.shift(id)
            // console.log(x);
            if (transactionPrimaryId.length == 0) {
                setCheckbox(false);
            }
        }
    }

    const [permission_data, setPermission_data] = useState([])

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            // console.log('response', response);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }
    const user_types = JSON.parse(lenderData)
    const user_type = user_types.user_type

    useEffect(() => {
        if (user_type == "SUB-LENDERS") {
            GetPermissions(token);
        }
    }, []);

    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);
    } else {

    }
    const [view_loan_application, setview_loan_application] = useState(false)
    const [add_loan_application, setadd_loan_application] = useState(false)
    const [manage_loan_application, setmanage_loan_application] = useState(false)

    useEffect(() => {
        if (role) {
            const options = role;
            // console.log(options)
            options.length > 0 && options.forEach((option) => {
                if (option.key == "view_loan_application") {
                    setview_loan_application(true);
                }
                if (option.key == "add_loan_application") {
                    setadd_loan_application(true);
                }
                if (option.key == "manage_loan_application") {
                    setmanage_loan_application(true);
                }

            });
        }
    }, [role]);

    // console.log(status, "status", nfc_site_name)
    const Form = useFormik({
        initialValues: {
            user_loan_id: "",
            message: "",
            image: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // message: yup.string().required('Please add a message'),
            // image: yup.string().required('Please Select Image')
        }),

        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("user_loan_id", userloanID);
            formData.append("message", Form.getFieldProps('message').value);
            formData.append("image", imageUpload);
            formData.append("time_zone", date_Format)

            // updatechat(token, formData)
            postApi("/loan-chat-with-user", formData, true).then((res) => {
                if (res.status == true) {
                    resetForm({ values: '' });
                    getBorrowerdetail(userloanID)
                    setimageUpload({})
                    // ref.current.click()

                }
            }).catch((err) => {
                console.log(err)
            })
        }
    })



    const handleImageRemove = () => {
        // Clear the image data from the state
        setimageUpload(null);
    };

    const handleUpdateStatus = (loanID, id) => {
        // console.log(loanID, id, "________________")
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = JSON.stringify({
                    "loan_status_id": id
                })

                // const response = await EditLoanStatus(token, data, loanID)
                const response = await postApi(`/update-loan-status/${loanID}`, {
                    loan_status_id: id
                })
                if (response?.status) {
                    Swal.fire(
                        'Approve!',
                        'Application EWA Advance Status Updated Successfully.',
                        'success'
                    )
                    ViewLoanCases()
                }
                else {
                    toast.error("something went wrong")
                }

            }
        })
    }

    const handleReviseDisbursal = (loanID, id, employerComment, disbursed_loan_amount) => {
        // console.log(loanID, id)
        Swal.fire({
            title: 'Employer Rejection Reason!',
            text: employerComment,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Revise Disbursal!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // let data = JSON.stringify({
                //     "loan_status_id": id
                // })
                navigate(`/lender/revise_disbursed_emi/${loanID}`, { state: { revised_emi: "revised_emi" } });

            }
        })
    }

    const HandleRevertsign = (loanID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await getApi(`/revert-borrower-sign/${loanID}`)
                if (response?.status) {
                    Swal.fire(
                        'Approve!',
                        'Application EWA Advance Status Reverted to Employee Sign Successfully.',
                        'success'
                    )
                    ViewLoanCases()
                }
                else {
                    toast.error("something went wrong")
                }

            }
        })
    }

    const DisbursalModal = (id, require_loan_amount) => {
        navigate(prefix + `/disbursed_emi/${id}`, { state: { require_loan_amount: require_loan_amount } })

    }

    const [Message, setMessage] = useState([]);
    const [IDs, setIDs] = useState()

    const GetNotesMessage = async (token, ids) => {
        // console.log(token, noteID)
        // const response = await getnotesmessage(token, ids)
        const response = await getApi(`/get-loan-notes/${ids}`)
        if (response?.status == true) {
            setMessage(response.data)
        } else {
            console.log(response.message)
            toast.error(response.message)
        }
    }

    const FormEdit = useFormik({
        initialValues: {
            user_loan_id: "",
            note: ""

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            note: yup.string().required('Please Add Notes'),


        }),
        onSubmit: async values => {
            // const data = JSON.stringify({
            //     user_loan_id: IDs,
            //     note: values.note,

            // })

            // const response = await addloannote(token, data)
            const response = await postApi("/add-loan-note", {
                user_loan_id: IDs,
                note: values.note,
            })

            if (response.status === true) {
                toast.success(response.message);
                FormEdit.resetForm()
                GetNotesMessage(token, IDs)

            } else {
                console.log('error', response);
                toast.error(response);
            }
        }
    });

    const chatNotification = async () => {
        try {
            const response = await getApi('/loan-chat-notification')
            if (response.status) {
                setNotificationCount(response.unReadCount)
                setChatMsgNotification(response.data)
            } else {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        chatNotification()
    }, [])

    const handleMarkNotificationRead = async (chat_id) => {
        try {
            const response = await getApi(`/mark-notification-read?chat_id=${chat_id}`)
            if (response.status) {
                chatNotification()
                // setNotificationCount(response.unReadCount)
                // setChatMsgNotification(response.data)
            } else {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const formatTimeDiff = (created_at) => {
        let timeDiff = Date.now() - Date.parse(created_at);
        let minutes = Math.floor(timeDiff / 60000);
        let hours = Math.floor(timeDiff / 3600000);
        let days = Math.floor(timeDiff / 86400000);

        if (days > 1) {
            return `${days} days ago`;
        } else if (days === 1) {
            let remainingHours = hours % 24;
            if (remainingHours > 0) {
                return `1 day and ${remainingHours} hr${remainingHours === 1 ? '' : 's'} ago`;
            } else {
                return `1 day ago`;
            }
        } else if (hours > 1) {
            let remainingMinutes = minutes % 60;
            if (remainingMinutes > 0) {
                return `${hours} hrs and ${remainingMinutes} min${remainingMinutes === 1 ? '' : 's'} ago`;
            } else {
                return `${hours} hrs ago`;
            }
        } else if (hours === 1) {
            let remainingMinutes = minutes % 60;
            if (remainingMinutes > 0) {
                return `1 hrs and ${remainingMinutes} min${remainingMinutes === 1 ? '' : 's'} ago`;
            } else {
                return `1 hrs ago`;
            }
        } else {
            return `${minutes} min${minutes === 1 ? '' : 's'} ago`;
        }
    }

    const ref = useRef()

    const adddisburseForm = useFormik({
        initialValues: {
            comment: '',
            image: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            comment: yup.string().required('Please add note '),
            image: docUpload?.length == 0 ? yup.mixed().required('Please select Attachment') : yup.string()
        }),
        onSubmit: async values => {
            const formData = new FormData();
            formData.append("comment", adddisburseForm.getFieldProps('comment').value);
            // formData.append("image", docUpload);
            formData.append("loan_status_id", 6);
            for (let i = 0; i < docUpload.length; i++) {
                formData.append('image', docUpload[i]);
            }
            // AddSettlement(formData)
            postApi(`/disburse-loan-amount/${disburseID}`, formData, true).then((res) => {
                if (res.status == true) {
                    ViewLoanCases()
                    toast.success(res.message);
                    ref.current.click()
                    adddisburseForm.resetForm()
                    setDocUpload(null)
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    });



    const HandleDocfile = (e) => {
        const files = e.target.files;
        if (files) {
            setDocUpload([...docUpload, ...files]);
        }
    }

    const handleRemoveImage = (index) => {
        const newDocUpload = [...docUpload];
        newDocUpload.splice(index, 1);
        setDocUpload(newDocUpload);
    }

    const handleNavigate = (user_loan_id) => {
        navigate(`${prefix}/manage_pin/${user_loan_id}`, { state: { isPinUpdated } });
    };


    const handlefileData = (e) => {
        const pdf = e.target.files[0];
        if (pdf && pdf.type !== 'application/pdf') {
            toast.error('Only PDF files are accepted');
            e.target.value = '';
            setFile(null);
            return;
        }
        setFile(pdf);
    };


    const UpdateStatus = async () => {
        try {
            const formData = new FormData();
            formData.append("user_loan_id", getUserLoanID);
            formData.append("image", file);
            const response = await postApi("/upload-employer-ewa-agreement", formData, true);
            if (response.status) {
                ViewLoanCases()
                console.log(response.message);
                CloseModal.current.click()
                fileInputRef.current.value = '';
                setFile(null);
            } else {
                console.log(response.status);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleClose = () => {
        setFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Manage EWA Advance</h4>
                                {/* {checkbox  ? <button className="btn btn-primary" onClick={updatesynch}>Sync</button>:
                                 <button className="btn btn-primary" disabled>Sync</button>} */}
                            </div>
                            {/* <div style={{ marginLeft: '360px' , marginTop: '10px' }}>
                            <h5 > {TotalCount ? `Total: $${TotalCount}` : ""}</h5></div>
                            <h5 > <b>{num3 ? `Interest:  $${num3}` : ""}</b></h5> */}
                            <div className="page-rightheader">
                                {/* <h6 className="ml-2"> Total Interest : $5647</h6> */}
                                <div className="btn-list">
                                    {(user_type == 'lenders' || add_loan_application) && (<Link to="/lender/add_ewa_advance" className="btn btn-primary btn-pill mt-2"><i className="fa fa-plus me-2"></i> Add EWA Advance</Link>)}
                                    {dataArray.length > 0 ? <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel> :
                                        <button className='btn btn-primary btn-pill mt-2' disabled><i className="fa fa-download me-2"></i> Download Details</button>}
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                                {/* <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                                    <option>Select Status</option>
                                                    <option value="all task" >All Status</option>
                                                    <option value="Assigned"  >Assigned</option>
                                                    <option value="Unassigned" >Unassigned</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12"></div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12"></div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12 d-flex justify-content-center align-items-center">

                                <div>
                                    <div className="form-group">
                                        <label className="form-label">Search</label>
                                        <div className="row g-xs">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setpagesCount('1') }} />
                                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <button className="btn fas fa-bell text-white"
                                        style={{ borderRadius: '30%', backgroundColor: '#2a3bac', padding: '10px', border: 'none', marginLeft: '10px' }}
                                        type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                                        onClick={() => {
                                            // setLeaveNotificationCount(0)
                                            // HandleLeaveCount();
                                        }}
                                    >
                                        {notificationCount > 0
                                            && <span
                                                className="bg-danger"
                                                style={{
                                                    position: 'absolute', top: '1.5rem', right: '5',
                                                    color: 'white', fontSize: '12px', padding: '2px 5px', borderRadius: '40%'
                                                }}
                                            >{notificationCount}</span>
                                        }
                                    </button>


                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu1" style={{ width: "15rem", left: '2.5rem', cursor: "pointer", overflow: 'scroll', maxHeight: '300px' }}>
                                        {chatMsgNotification?.length > 0
                                            ? chatMsgNotification.map((cusDetail, index) => {
                                                return (
                                                    <>
                                                        <li
                                                            key={index}
                                                            className='px-2' onClick={() => {
                                                                getBorrowerdetail(cusDetail.user_loan_id, cusDetail?.loanDetail?.first_name)
                                                                handleMarkNotificationRead(cusDetail.id)
                                                            }}
                                                            // data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                            data-bs-target="#chatBubble" data-bs-toggle="modal"
                                                        >
                                                            <div className='d-flex align-items-center'>
                                                                <span className='fas fa-user text-primary px-2' style={{ fontSize: '2rem' }}></span>
                                                                <div className='px-2' style={{ width: '100%' }}>
                                                                    <div className='d-flex justify-content-between' /* style={{ fontSize: '0.9rem' }} */>
                                                                        <span class="dropdown-item5 fw-bold">{cusDetail?.loanDetail?.first_name} {cusDetail?.loanDetail?.last_name}</span>
                                                                        {/* <span className="text-primary" >({cusDetail?.leave_type})</span> */}
                                                                        {/* <span className="text-primary" >Leave type</span> */}
                                                                    </div>

                                                                    <span style={{ fontSize: '0.8rem' }}><b>Msg:</b> {cusDetail?.message} </span> <br />
                                                                    <span className='text-capitalize' style={{ fontSize: '0.9rem' }}>{cusDetail?.loanDetail?.loan_type?.replace('_', ' ')} </span>

                                                                    <div className='d-flex justify-content-between'>
                                                                        <h6 className='' style={{ fontSize: '0.8rem' }}>
                                                                            {formatTimeDiff(moment.utc(cusDetail?.created_at).format("YYYY-MM-DD HH:mm:ss"))}
                                                                        </h6>
                                                                        {/* <h6 style={{ fontSize: '0.8rem', textTransform: 'uppercase' }}>
                                                                        {cusDetail?.status === 'pending' ? (
                                                                            <span className='text-warning'>{cusDetail?.status}</span>
                                                                        ) : cusDetail?.status === "rejected" ? (
                                                                            <span className='text-danger'>{cusDetail?.status}</span>
                                                                        ) : (
                                                                            <span className='text-success'>{cusDetail?.status}</span>
                                                                        )}
                                                                    </h6> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                        {/* {index != chatMsgNotification.length - 1 &&
                                                        } */}
                                                        <hr className='dropdown-divider' />

                                                        {index == chatMsgNotification.length - 1 &&
                                                            <li className='text-center'>
                                                                <h6 className='m-0 text-center text-primary btn btn-outline-primary' onClick={(e) => handleMarkNotificationRead('all')}>
                                                                    Mark all as read
                                                                </h6>
                                                            </li>
                                                        }
                                                    </>
                                                )
                                            })
                                            : <li>
                                                <h6 className='m-0 text-center'>
                                                    No unread messages
                                                </h6>
                                            </li>
                                        }
                                        {/* <li className='px-2'
                                            data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <div className='text-center text-primary' onClick={() => ViewAllLeaveRequest()}>View All Request</div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        {/*   <th className="font-weight-bold">Signature</th>*/}
                                                        <th className="font-weight-bold">Loan ID</th>
                                                        <th className="font-weight-bold">Borrower Name  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}> </i>}</th>
                                                        <th className="font-weight-bold">Employer Email  {sortedBy == "employer_email" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_email') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_email') }}> </i>}</th>
                                                        <th className="font-weight-bold">EWA Type  {sortedBy == "loan_type" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_type') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_type') }}> </i>}</th>
                                                        <th className="font-weight-bold">EWA Status  {sortedBy == "loan_status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_status') }}> </i>}</th>
                                                        <th className="font-weight-bold">Created Date  {sortedBy == "createdAt" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }}> </i>}</th>
                                                        {(user_type == "lenders" || manage_loan_application) && <th className="font-weight-bold">Action </th>}
                                                        <th className="font-weight-bold">Comment Box</th>
                                                        <th className="font-weight-bold">Note</th>
                                                        <th className="font-weight-bold">Docs</th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody><td /><td /><Loader /></tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { user_loan_id, loan_status, loan_type, last_name, first_name, mobile_number, employer_email } = option;
                                                                return (
                                                                    <tr key={serial_num}>
                                                                        <td><span className="font-weight-normal1"> {option?.user_loan_id ? user_loan_id : "- -"} </span></td>
                                                                        {/* <Link className="text-primary" to={{ pathname: `${prefix}/case_detail/${user_loan_id}`, state: { user_loan_id } }}> */}
                                                                        <td><div className="text-primary" style={{ cursor: 'pointer' }} onClick={() => handleNavigate(user_loan_id)}>
                                                                            <span className="font-weight-normal1">{first_name ? first_name : "--"}  {last_name ? last_name : ""}<br />{mobile_number}</span></div></td>
                                                                        <td><span className="font-weight-normal1" style={{ textTransform: 'none' }}> {option?.employer_email ? employer_email : "- -"} </span></td>
                                                                        <td ><span className="font-weight-normal1">{option.loan_type == "personal_loan" ? "Personal" : option.loan_type == "business_loan" ? "Business" : option.loan_type}</span></td>
                                                                        <td><span className="font-weight-normal1"> {option?.loan_status?.status_name == "Disbursal" ? "Disbursed" : loan_status?.status_name} </span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                        {/* {(user_type == "lenders" || manage_loan_application) && <td><span>  <button type="button" disabled={(option?.loan_status?.status_name === "Disbursal" || option?.loan_status?.status_name === "Rejected" || option?.loan_status?.status_name === "Closed")} className="btn btn-primary" data-bs-target="#edit_settlement" data-bs-toggle="modal" onClick={() => { setID(option.user_loan_id); setSite_Name(option?.loan_status?.status_id); getStatus(token); }}  >}Update Status</button> </span></td>} */}

                                                                        {(user_type == "lenders" || manage_loan_application) && <td><span>{
                                                                            (option?.loan_status?.status_name === "Open" ?
                                                                                <button className="btn btn-primary btn-sm"
                                                                                    onClick={() => { handleUpdateStatus(option.user_loan_id, "2") }} >Initial Review </button>
                                                                                : option?.loan_status?.status_name === "Initial Review" ?
                                                                                    <span>
                                                                                        <button className="btn btn-primary btn-sm m-1"
                                                                                            onClick={() => { handleUpdateStatus(option.user_loan_id, "3") }}>Underwriting </button>
                                                                                        <button className="btn btn-warning btn-sm"
                                                                                            onClick={() => { handleUpdateStatus(option.user_loan_id, "1") }}>Open </button></span>


                                                                                    : option?.loan_status?.status_name === "Underwriting" ?
                                                                                        <span>
                                                                                            <button className="btn btn-success btn-sm m-1"
                                                                                                onClick={() => { DisbursalModal(option.user_loan_id, option?.require_loan_amount) }}
                                                                                        /* onClick={() => { handleUpdateStatus(option.user_loan_id, "4") }} */>Approved </button>
                                                                                            <button className="btn btn-primary btn-sm m-1"
                                                                                                onClick={() => { handleUpdateStatus(option.user_loan_id, "2") }}>Initial Review </button>
                                                                                            <button className="btn btn-danger btn-sm"
                                                                                                onClick={() => { handleUpdateStatus(option.user_loan_id, "5") }}>Rejected </button>
                                                                                        </span>

                                                                                        : option?.loan_status?.status_name === "Approved"
                                                                                            ? option?.agreement_signature_2 === null
                                                                                                ? <>
                                                                                                    <span className='text-danger mt-1'>Waiting for<br />Borrower Sign </span>
                                                                                                    <button className="btn btn-danger btn-sm" style={{ marginTop: "-20px", marginRight: '5px' }}
                                                                                                        onClick={() => { handleUpdateStatus(option.user_loan_id, "8") }}>Closed
                                                                                                    </button>
                                                                                                </>
                                                                                                :
                                                                                                (option?.employer_approval == null || option?.employer_approval == 'Pending')
                                                                                                    ? <> <span className='text-danger mt-1'>Waiting for<br /> Employer Sign </span>

                                                                                                        <button className="btn btn-warning btn-sm fs-10" style={{ marginTop: "-20px", marginRight: '5px' }}
                                                                                                            onClick={() => HandleRevertsign(option?.user_loan_id)}
                                                                                                        >Edit Employer  <br />email in Agreement
                                                                                                        </button>
                                                                                                        <br />
                                                                                                        <button className="btn btn-danger btn-sm" style={{ marginTop: "5px", marginRight: '5px' }}
                                                                                                            onClick={() => { handleUpdateStatus(option.user_loan_id, "8") }}>Closed
                                                                                                        </button>
                                                                                                        <button className="btn btn-primary btn-sm mt-2" onClick={() => setGetUserLoanID(option?.user_loan_id)}
                                                                                                            data-bs-toggle="modal" data-bs-target="#staticBackdrop">Proceed Manually</button>

                                                                                                    </>
                                                                                                    : option?.employer_approval == 'Rejected'
                                                                                                        ?
                                                                                                        <>
                                                                                                            <span className='text-danger mt-1 me-2'>Rejected By<br />Employer &nbsp;&nbsp;&nbsp;</span>
                                                                                                            <button className="btn btn-primary btn-sm "
                                                                                                                style={{ marginTop: "-15px", marginRight: '5px' }}
                                                                                                                onClick={() => { handleReviseDisbursal(option?.user_loan_id, "4", option.employer_comment) }}
                                                                                                            >Revise Disbursal</button>
                                                                                                        </>
                                                                                                        : option?.employer_approval == 'Approved' &&

                                                                                                        <button className="btn btn-primary "
                                                                                                            style={{ marginRight: '5px' }}
                                                                                                            data-bs-target="#add_settlement"
                                                                                                            data-bs-toggle="modal"
                                                                                                            onClick={() => setdisburseID(option?.user_loan_id)}
                                                                                                        // onClick={() => { handleUpdateStatus(option.user_loan_id, "6") }}
                                                                                                        >Disbursal </button>

                                                                                            : option?.loan_status?.status_name === "Disbursal"
                                                                                                ? <span>Disbursed</span>
                                                                                                : <span>{option?.loan_status?.status_name}</span>
                                                                            )
                                                                        }
                                                                        </span>
                                                                        </td>}
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    getBorrowerdetail(option.user_loan_id, first_name)
                                                                                }}
                                                                                className="btn btn-primary"
                                                                                data-bs-target="#chatBubble"
                                                                                data-bs-toggle="modal"
                                                                            >
                                                                                <i className="las la-comments coseicon"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button className="btn btn-primary" type="button"
                                                                                onClick={() => { setIDs(option?.user_loan_id); GetNotesMessage(token, option?.user_loan_id) }}
                                                                                data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Note</button>
                                                                        </td> <td>
                                                                            {option?.agreement_doc_1 !== null ?

                                                                                <div class="dropdown">
                                                                                    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        View Docs
                                                                                    </button>
                                                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                                        {/* <button class="dropdown-item " type="button">
                                                                                            <a className="text-dark" href={option.agreement_signature_1} download><i className='fa fa-download me-1 text-primary' />Employee Signature 1</a></button>
                                                                                        <button class="dropdown-item" type="button">
                                                                                            <a className="text-dark" href={option.agreement_signature_2} download><i className='fa fa-download me-1 text-primary' />Employee Signature 2</a></button>
                                                                                        {option?.employer_signature && <button class="dropdown-item" type="button">
                                                                                            <a className="text-dark" href={option.employer_signature} download><i className='fa fa-download me-1 text-primary' />Employer Signature </a></button>} */}
                                                                                        <button class="dropdown-item " type="button">
                                                                                            <a className="text-dark" href={option.agreement_doc_1} download><i className='fa fa-download me-1 text-primary' />Employee Agreement Doc 1</a></button>
                                                                                        <button class="dropdown-item" type="button">
                                                                                            <a className="text-dark" href={option.agreement_doc_2} download><i className='fa fa-download me-1 text-primary' />Employee Agreement Doc 2</a></button>
                                                                                    </div>
                                                                                </div> : <span className='text-primary fw-bold'>No Docs Found</span>}</td>

                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                            {/* Update Status Modal start */}
                            {/* <div className="modal fade effect-scale show" id="edit_settlement" aria-modal="true" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content modal-content-demo">
                                        <div className="modal-header">
                                            <h3 className="modal-title">Update Loan Status</h3>
                                            <button ref={tableRef} aria-label="Close" className="btn-close" data-bs-dismiss="modal"
                                                onClick={() => { setID(); setSite_Name(); }}
                                                type="button"><span
                                                    aria-hidden="true">×</span></button>
                                        </div>
                                        <div className="modal-body application-modal">
                                            <form className="mt-1 row" onSubmit={FormEdit.handleSubmit}>
                                                <div className="row">
                                                    <div className="row g-xs">
                                                        <div className="col-12">
                                                            <select
                                                                className="form-control"
                                                                name="nfc_site_name"
                                                                {...FormEdit.getFieldProps("nfc_site_name")}
                                                            >
                                                                <option value="">Select Loan Status</option>
                                                                {status?.map((item, index) => {
                                                                    const selectedValue = FormEdit.getFieldProps("nfc_site_name").value;
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={item.status_id}
                                                                            disabled={
                                                                                (selectedValue === 1 && item?.status_id !== 2) ||
                                                                                (selectedValue === 2 && item?.status_id !== 1 && item?.status_id !== 3) ||
                                                                                (selectedValue === 3 && item?.status_id !== 2 && item?.status_id !== 4 && item?.status_id !== 5) ||
                                                                                (selectedValue === 4 && item?.status_id !== 8 && item?.status_id !== 6) ||
                                                                                (selectedValue === 6 && item?.status_id !== 4 && item?.status_id !== 8)
                                                                            } >{item.status_name}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {FormEdit.touched.nfc_site_name && FormEdit.errors.nfc_site_name ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.nfc_site_name}</div> : ''}
                                                        </div>
                                                        {FormEdit.getFieldProps("nfc_site_name").value === '6' &&
                                                            <form className="row" id='registerForm' onSubmit={FormEdit.handleSubmit}>
                                                                <div className="col">
                                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Amount</label>
                                                                            <div className="row g-xs">
                                                                                <div className="input-group">
                                                                                    <input type="number" className="form-control" placeholder="Enter Amount" {...FormEdit.getFieldProps("amount")} />
                                                                                </div>
                                                                                {FormEdit.touched.amount && FormEdit.errors.amount ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.amount}</div> : ''}
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">

                                                                        <div className="form-group">
                                                                            <label className="form-label">Total EMI</label>
                                                                            <div className="row g-xs">
                                                                                <div className="input-group">
                                                                                    <input type="number" className="form-control" placeholder="Enter Total EMI" {...FormEdit.getFieldProps("total_emi")} />
                                                                                </div>
                                                                                {FormEdit.touched.total_emi && FormEdit.errors.total_emi ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.total_emi}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Comment</label>
                                                                            <div className="row g-xs">
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="Enter Comment" {...FormEdit.getFieldProps("comment")} />
                                                                                </div>
                                                                                {FormEdit.touched.comment && FormEdit.errors.comment ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.comment}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col">

                                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Monthly EMI Amount</label>
                                                                            <div className="row g-xs">
                                                                                <div className="input-group">
                                                                                    <input type="number" className="form-control" placeholder="Enter Deduction Amount Limit" {...FormEdit.getFieldProps("emi_monthly_amount")} />
                                                                                </div>
                                                                                {FormEdit.touched.emi_monthly_amount && FormEdit.errors.emi_monthly_amount ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.emi_monthly_amount}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="form-label">EMI Start Date</label>
                                                                            <div className="row g-xs">
                                                                                <div className="input-group">
                                                                                    <input type="date" className="form-control" placeholder="Enter EMI Start Date" {...FormEdit.getFieldProps("emi_start_date")} />
                                                                                </div>
                                                                                {FormEdit.touched.emi_start_date && FormEdit.errors.emi_start_date ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.emi_start_date}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 mb-5">
                                                                    <div className="col-md-12">
                                                                        <input type="submit" name="next" className="next action-button apply-now-btn ml-00 mt-1" value="Submit" />
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        }
                                                    </div>
                                                    <div className="form-footer mt-2">
                                                        <button type="submit" className="btn btn-primary" aria-hidden="true">
                                                            Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* Update Status Modal End */}

                            {/* Note Modal Start */}
                            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                                <div className="offcanvas-header">
                                    <h5 id="offcanvasRightLabel" className='text-primary'>Chat Activity</h5>
                                    {/* <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button">
                                                                                        <span aria-hidden="true">×</span></button> */}
                                    <button type="button" className="btn-close"
                                        data-bs-dismiss="offcanvas" aria-label="Close" aria-hidden="true">
                                        ×
                                    </button>
                                </div>
                                <div className="offcanvas-body">
                                    <form className="row " id='registerForm' onSubmit={FormEdit.handleSubmit} >
                                        <div className="card w-100 sideNoteBox">
                                            {Message.length > 0 && Message.map((option, index) => {
                                                return (
                                                    <div className="card-body" key={index}>
                                                        <h5 className="card-title mb-0">{option?.subLenderData?.full_name}</h5>
                                                        <p className="card-text">{option?.note}<br />
                                                            <span className='text-success'>{TimeZone(option?.created_at).timeZone}</span></p>
                                                        {/* <p className="card-text"></p> */}
                                                    </div>
                                                )
                                            })}
                                            {Message.length == 0 &&
                                                <div className="card-body">
                                                    <h5 className="card-title mb-0">No Data Available</h5>

                                                </div>}
                                        </div>

                                        <div className="addNoteBtnTxt">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12 pl-00">
                                                <div className="form-group mb-1">
                                                    <label className="form-label"></label>
                                                    <div className="row g-xs">
                                                        <div className="input-group pl-05">
                                                            <textarea placeholder='Add Notes' {...FormEdit.getFieldProps("note")} className="form-control formtestarea" />
                                                            {FormEdit.touched.note && FormEdit.errors.note ?

                                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.note}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 pl-00">
                                                <button type="submit" className="btn btn-primary mb-6 w-md mb-1 mt-1"> Save </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {/* Note Modal End */}



                            {/* Chat Bot Modal start */}
                            <div className="modal fade effect-scale show" id="chatBubble" aria-modal="true" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content modal-content-demo">
                                        <div className="modal-header">
                                            <h3 className="modal-title">Applicant Name: {username ? username : "--"}</h3>
                                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                                aria-hidden="true">×</span></button>
                                        </div>
                                        <div className="modal-body application-modal">
                                            <form className="mt-1 row" onSubmit={Form.handleSubmit}>
                                                <div className="col-md-12">
                                                    <div className="chat-bot">
                                                        <div className="messenger">
                                                            {/* <div className="timestamp">04:45 AM</div> */}
                                                            <div className="chatroom">
                                                                {data?.length > 0 && data?.map((option, index) => (
                                                                    <div key={index} className={option.sender === "USER" ? "msg msg-left" : "msg msg-right"}>
                                                                        <div className="bubble">
                                                                            {option?.image != null ? <Image src={option?.image} style={{ width: '100px', height: '100px' }} alt="loading" /> : ""}
                                                                            <h6 className="name">{option?.message ? option.message : ""}</h6>
                                                                        </div>
                                                                        <div className="timestamp">{TimeZone(option?.created_at).timeZone}</div>

                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="type-area">
                                                                <label for="fileInput" className="upload-label">
                                                                    <span className="fa-solid fa-paperclip upload-icon"></span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    id="fileInput"
                                                                    onChange={(e) => setimageUpload(e.target.files[0])}
                                                                    style={{ display: 'none' }}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className="typing"
                                                                    placeholder="Type Here..."
                                                                    {...Form.getFieldProps("message")}
                                                                />
                                                                <button type="submit" aria-hidden="true" disabled={!Form.values.message && !imageUpload?.name} className="sendBtnChat">
                                                                    <i className="las la-paper-plane" ></i>
                                                                </button>
                                                            </div>
                                                            {/* {Form.touched.message && Form.errors.message ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.message}</div> : ''} */}


                                                            {/* {imageUpload && imageUpload.name ? (
                                                                <Image src={URL.createObjectURL(imageUpload)} className='m-2' style={{ maxWidth: '40%', height: '100px' }} alt="" />
                                                            ) : (
                                                                ""
                                                            )} */} </div>
                                                        {imageUpload && imageUpload?.name ? (
                                                            <div className="selected-image">
                                                                <img src={URL.createObjectURL(imageUpload)} className='m-2' style={{ maxWidth: '100%', height: '100px' }} alt="" />
                                                                {/* <Link to="" className="upload__img-close"> */}
                                                                <i className="fa-solid fa-close fa-fw" onClick={handleImageRemove} style={{ cursor: "pointer" }}></i>
                                                                {/* </Link> */}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Chat Bot Modal End */}


                            {/*************************** For Add loan disburse Document modal  ****************************/}
                            <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                                <div className="modal-dialog modal-dialog-centered " role="document">
                                    <div className="modal-content modal-content-demo bg-white">
                                        <div className="modal-header">
                                            <h3 className="modal-title">Add Disburse Attachment </h3>
                                            <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                                aria-hidden="true">×</span></button>
                                        </div>
                                        <div className="modal-body application-modal">
                                            <form className="mt-1 row" onSubmit={adddisburseForm.handleSubmit}>
                                                <div className="row">
                                                    <div className="row g-xs">
                                                        <label className="form-label">Comment</label>
                                                        <div className="input-group file-browser mb-2">
                                                            <input type="text" className="form-control" {...adddisburseForm.getFieldProps('comment')} placeholder="Enter Comment here..."
                                                            />
                                                        </div>
                                                        {adddisburseForm.touched.comment && adddisburseForm.errors.comment ?
                                                            <div className="invalid-feedback" style={{ display: "block" }}>{adddisburseForm.errors.comment}</div> : ''}
                                                        <label className="form-label">Attachment</label>
                                                        <div className="upload__box">
                                                            <div className="upload__btn-box">
                                                                <label className="upload__btn">
                                                                    <i className="fa-solid fa-arrow-up-from-bracket fa-fw"></i>
                                                                    <p>Upload Attachment <span className="addmore-plus"><i
                                                                        className="fa-solid fa-plus fa-fw"></i></span>
                                                                        <small
                                                                            style={{ display: "block", color: "#a5a5a5" }}>(JPG,
                                                                            PNG only)</small>
                                                                    </p>
                                                                    <input type="file" accept=".jpg, .png"
                                                                        onChange={(e) => HandleDocfile(e)}
                                                                        multiple
                                                                        className="upload__inputfile" />
                                                                </label>
                                                            </div>
                                                            {adddisburseForm.touched.image && adddisburseForm.errors.image && docUpload.length == 0 ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{adddisburseForm.errors.image}</div> : ''}
                                                        </div>
                                                        {docUpload?.length > 0 && (
                                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                {docUpload.map((file, index) => (
                                                                    <div key={index} style={{ position: 'relative', margin: '0.2rem' }}>
                                                                        <img src={URL.createObjectURL(file)} style={{ border: '1px solid #c6c2c2', width: '70px', height: '70px' }} alt='...' />
                                                                        <i className="fa fa-times text-danger" style={{
                                                                            position: 'absolute',
                                                                            top: 1,
                                                                            right: 1,
                                                                            cursor: 'pointer',
                                                                            // backgroundColor: 'primary',
                                                                            borderRadius: '50%',
                                                                            padding: 0,
                                                                            fontSize: 15
                                                                        }}
                                                                            onClick={() => handleRemoveImage(index)}></i>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-footer mt-3">
                                                        <button type="submit" className="btn btn-primary" aria-hidden="true"  >
                                                            Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/************************** PROCEED MANUALLY MODAL START **************************************/}
                            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-center">
                                    <form>
                                        <div class="modal-content">


                                            <div class="modal-header">
                                                <h5 class="modal-title" id="staticBackdropLabel">Proceed Manually</h5>
                                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                            </div>
                                            <div class="modal-body">
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <label>Direct Deduction Form</label>
                                                        <input
                                                            ref={fileInputRef} className='form-control' type='file' onChange={handlefileData} accept='.pdf' ></input>
                                                        <span className='text-danger fs-11'>Note: *Please upload PDF only</span>                                                   </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button ref={CloseModal} onClick={handleClose} type="button" class="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={UpdateStatus}
                                                    disabled={!file || file.type !== 'application/pdf'}
                                                >Proceed</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/************************** PROCEED MANUALLY MODAL END **************************************/}

                        </div>
                    </div>
                </div >

            }
        </>
    )

}
export default ManageEwaAdvance


