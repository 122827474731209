import React from 'react'

const PersonalDetail = ({ employeeDetail }) => {
    function calculateAge(dob) {
        if (!dob) {
            return '';
        }

        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const currentMonth = today.getMonth();
        const birthMonth = birthDate.getMonth();
        const currentDate = today.getDate();
        const birthDay = birthDate.getDate();

        if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDate < birthDay)) {
            age--;
        }
        return age.toString();
    }

    const age = calculateAge(employeeDetail ? employeeDetail?.date_of_birth : 0);

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-2">
                <div className="modal-body application-modal px-0">
                    <div className="row">
                        <div className="col-lg-12 col-xl-12">
                            <div className="">
                                <div className="main-content-body main-content-body-contacts">
                                    <h6>Borrower Information</h6>
                                    <div className='btn_group' style={{}}>
                                    </div>
                                    <div className="main-contact-info-body">
                                        <div className="media-list p-0">
                                            <div className="media py-4 mt-0">
                                                <div className="media-body">
                                                    {/* require_loan_amount */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-file"></i> </div>
                                                        <div className="w-70"> <label>Tin :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.tin ? employeeDetail?.tin : '--'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                        <div className="w-70"> <label>FNPF: </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.fnpf ? employeeDetail?.fnpf : '--'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-user"></i> </div>
                                                        <div className="w-70"> <label>First Name : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.first_name ? employeeDetail?.first_name : ''}</span> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media py-4 mt-0">
                                                <div className="media-body">

                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-user-circle"></i> </div>
                                                        <div className="w-70"> <label>Middle Name : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.middle_name ? employeeDetail?.middle_name : "--"}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-user-tie"></i> </div>
                                                        <div className="w-70"> <label>Last Name:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.last_name ? employeeDetail?.last_name : ''}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-mail-bulk"></i> </div>
                                                        <div className="w-70"> <label>Personal Email :</label> <span className="font-weight-normal1 fs-14" style={{ textTransform: 'none' }}>{employeeDetail?.personal_email ? employeeDetail?.personal_email : ''}</span> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media py-4 mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                        <div className="w-70">
                                                            <label>Residential Address :</label>
                                                            <span className="font-weight-normal1 fs-14 dropdown-item5">{employeeDetail?.residental_address ? employeeDetail?.residental_address : ''}</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-user-circle"></i> </div>
                                                        <div className="w-70"> <label>Residential Period  : </label>
                                                            <span className="font-weight-normal1 fs-14  dropdown-item5">{employeeDetail?.period ? employeeDetail?.period : ""}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                        <div className="w-70"> <label>Postal Address :</label>
                                                            <span className="font-weight-normal1 fs-14 dropdown-item5">{employeeDetail?.postal_address ? employeeDetail?.postal_address : '--'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                        <div className="w-70">
                                                            <label>Postal Period:</label>
                                                            <span className="font-weight-normal1 fs-14">{employeeDetail?.postal_period ? employeeDetail?.postal_period : ''}</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-address-card"></i></div>
                                                        <div className="w-70">
                                                            <label>Previous Address :</label>
                                                            <span className="font-weight-normal1 fs-14 dropdown-item5">{employeeDetail?.previus_address ? employeeDetail?.previus_address : '--'}</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                        <div className="w-70"> <label>Previous Period :</label>
                                                            <span className="font-weight-normal1 fs-14">{employeeDetail?.previous_period ? employeeDetail?.previous_period : '--'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">

                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-mail-bulk"></i> </div>
                                                        <div className="w-70"> <label>Work Email :</label> <span className="font-weight-normal1 fs-14" style={{ textTransform: 'none' }}>{employeeDetail?.work_email ? employeeDetail?.work_email : '--'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                        <div className="w-70"> <label>Telephone(H) :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.telephone_home ? employeeDetail?.telephone_home : '--'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone-alt"></i> </div>
                                                        <div className="w-70"> <label>Telephone(W) :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.telephone_work ? employeeDetail?.telephone_work : '--'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                        <div className="w-70"> <label>DOB. :</label> <span className="font-weight-normal1 fs-14">
                                                            {employeeDetail?.date_of_birth ? employeeDetail?.date_of_birth : "--"}

                                                        </span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                        <div className="w-70"> <label>Age. :</label> <span className="font-weight-normal1 fs-14">
                                                            {age ? age : "--"}
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                        <div className="w-70"> <label>Marital Status:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.martial_status ? employeeDetail?.martial_status : '--'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                        <div className="w-70"> <label>No. of Dependents. :</label> <span className="font-weight-normal1 fs-14">
                                                            {employeeDetail?.dependent_no ? employeeDetail?.dependent_no : "--"}

                                                        </span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                        <div className="w-70"> <label>Occupation. :</label> <span className="font-weight-normal1 fs-14">
                                                            {employeeDetail?.occupation ? employeeDetail?.occupation : "--"}
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                        <div className="w-70"> <label>Current Employer:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.current_employer ? employeeDetail?.current_employer : '--'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                        <div className="w-70"> <label>Employed Since. :</label> <span className="font-weight-normal1 fs-14">
                                                            {employeeDetail?.employed_since ? employeeDetail?.employed_since : "***"}

                                                        </span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                        <div className="w-70"> <label>Previous Employer. :</label> <span className="font-weight-normal1 fs-14">
                                                            {employeeDetail?.previus_employer ? employeeDetail?.previus_employer : "--"}
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                        <div className="w-70"> <label>Previous Employer Address:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.previus_office_address ? employeeDetail?.previus_office_address : '--'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                        <div className="w-70"> <label>Previous Employer Period. :</label> <span className="font-weight-normal1 fs-14">
                                                            {employeeDetail?.previous_employer_period ? employeeDetail?.previous_employer_period : "***"}

                                                        </span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                        <div className="w-70"> <label>Own House Estimated Value. :</label> <span className="font-weight-normal1 fs-14">
                                                            {employeeDetail?.house_est_value ? employeeDetail?.house_est_value : "--"}
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-piggy-bank"></i> </div>
                                                        <div className="w-70"> <label>Bank Name(Mortgage) :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.mortgaged ? employeeDetail?.mortgaged : '--'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                        <div className="w-70"> <label>Monthly Repayment Amount :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.monthly_payment ? employeeDetail?.monthly_payment : '--'}</span> </div>
                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                        <div className="w-70"> <label>Remaining Mortgage balance :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.mortgage_balance ? employeeDetail?.mortgage_balance : '--'}</span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                        <div className="w-70"> <label>Living House Status:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.notliving_house ? employeeDetail?.notliving_house : '--'}</span> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                        <div className="w-70"> <label>Bank Name:</label> <span className="font-weight-normal1 fs-14">
                                                            {employeeDetail?.bank_name ? employeeDetail?.bank_name : "--"}

                                                        </span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-birthday-cake"></i> </div>
                                                        <div className="w-70"> <label>Branch:</label> <span className="font-weight-normal1 fs-14">
                                                            {employeeDetail?.branch ? employeeDetail?.branch : "--"}

                                                        </span> </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                        <div className="w-70"> <label>Account Holder Name :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.account_name ? employeeDetail?.account_name : '--'}</span> </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="media py-4 border-top mt-0">
                                                <div className="media-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house-user"></i> </div>
                                                        <div className="w-70"> <label>Account No. :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.account_number ? employeeDetail?.account_number : '--'}</span> </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalDetail