import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { getApi, postApi } from '../../service/lenderService';
import Loader from '../../../components/Loader/loader';
import { Breadcrumbsecond } from '../../../components';
// import { enc } from 'crypto-js';

const ManagePin = () => {
    const prefix = process.env.REACT_APP_LENDER_PRIFIX
    const user_id = useParams();
    const location = useLocation()
    const navigate = useNavigate();
    const [showPassword, setshowPassword] = useState(false)
    const [showConfPassword, setshowConfPassword] = useState(false)
    const [verify_pin, setVerify_Pin] = useState(false)
    const [forgetPin, setForgetPin] = useState(false)
    const [spinner, setSpinner] = useState(false)

    // console.log(location?.state?.isPinUpdated, "****************")

    const IspinUpdated = location?.state?.isPinUpdated

    const createPinForm = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",
            verify_pin: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            new_password: !IspinUpdated ? yup.string().required('Please Enter New Pin') : "",
            confirm_password: !IspinUpdated ? yup.string().required('Please Enter Confirm Pin')/* .matches(regex, 'Please Enter Only Character Values') */
                .oneOf([yup.ref("new_password"), null], "Pin Must Be Same") : "",
            verify_pin: IspinUpdated ? yup.string().required('Please Enter Your Pin') : "",
        }),
        onSubmit: async values => {
            let response
            if (IspinUpdated) {
                response = await postApi("/verify-pin", {
                    verification_pin: values.verify_pin,
                })
            } else {
                response = await postApi("/register-pin", {
                    verification_pin: values.new_password,
                })
            }
            if (response.status) {
                toast.success(response.message);
                navigate(`${process.env.REACT_APP_LENDER_PRIFIX}/case_detail/${user_id.id}`, { state: { status: true } });
            } else {
                toast.error(response.message);
                console.log(response);
            }
        }
    });

    const createOTPForm = useFormik({
        initialValues: {
            verify_otp: "",
            new_pin: "",
            confirm_pin: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            verify_otp: yup.string().required('Please Enter OTP'),
            new_pin: yup.string().required('Please Enter New Pin'),
            confirm_pin: yup.string().required('Please Enter Confirm Pin')
                .oneOf([yup.ref("new_pin"), null], "Pin Must Be Same"),
        }),
        onSubmit: async values => {
            const response = await postApi("/change-pin", {
                otp: values.verify_otp,
                verification_pin: values.new_pin
            })

            if (response.status) {
                toast.success(response.message);
                navigate(`${prefix}/case_detail/${user_id.id}`, { state: { status: true } });
            } else {
                toast.error(response.message);
                console.log(response);
            }
        }
    });

    const CreateOtpFunction = async () => {
        setSpinner(true)
        try {
            const response = await getApi('/forget-pin')
            if (response.status) {
                toast.success(response.message)
                setForgetPin(true)
                setSpinner(false)
            } else {
                toast.error(response.message);
                setSpinner(false)
            }

        } catch (error) {
            console.log(error.message)
            setSpinner(false)
        }
    }





    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        <Breadcrumbsecond order={{ first: { name: "Manage EWA Advance", path: `${prefix}/manage_loan` }, second: { name: "Manage Pin" } }} />
                    </div>
                </div>
                {!forgetPin ?
                    <form className="row" id='registerForm' onSubmit={createPinForm.handleSubmit}>
                        {createPinForm.values.wrong_message ?
                            <div className="invalid-feedback mb-3 mt-2" style={{ display: "block", textAlign: "center" }}>{createPinForm.values.wrong_message}</div>
                            : ''}
                        <div className="row d-flex justify-content-center">
                            {IspinUpdated &&
                                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12 ">
                                    <div className="form-group">
                                        <label className="form-label">Enter Pin <i className="fas fa-key"></i></label>
                                        {/* <div className="row g-xs"> */}
                                        <div className="input-group">
                                            <input type={verify_pin ? "text" : "password"} className="form-control" placeholder="Enter Your Pin" {...createPinForm.getFieldProps("verify_pin")} />
                                            {verify_pin ?
                                                <span toggle="#password-field" onClick={() => setVerify_Pin(false)} className="fa fa-fw fa-eye field-icon toggle-password hideeyes me-3"></span>
                                                :
                                                <span toggle="#password-field" onClick={() => setVerify_Pin(true)} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes me-3"></span>

                                            }
                                        </div>
                                        {createPinForm.touched.verify_pin && createPinForm.errors.verify_pin ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{createPinForm.errors.verify_pin}</div> : ''}
                                        {/* </div> */}
                                    </div>
                                </div>}
                            {IspinUpdated == false &&
                                <>
                                    <div className="row d-flex justify-content-center mb-3" style={{ textTransform: 'none' }}>
                                        {/* <div className="col-md-12" > */}
                                        <p className="text-center mb-0">Create a new PIN to secure your account.</p>
                                        <p className="text-center">Please enter a new PIN and confirm it to complete the process.</p>
                                        {/* </div> */}
                                    </div>
                                    <div className='row'>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                            <div className="form-group">
                                                <label className="form-label">New Pin <i className="fas fa-key me-1"></i></label>
                                                <div className="input-group">
                                                    <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Enter New Pin" {...createPinForm.getFieldProps("new_password")} />
                                                    {showPassword ?
                                                        <span toggle="#password-field" onClick={() => setshowPassword(false)} className="fa fa-fw fa-eye field-icon toggle-password hideeyes me-3"></span>
                                                        :
                                                        <span toggle="#password-field" onClick={() => setshowPassword(true)} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes me-3"></span>
                                                    }
                                                </div>
                                                {createPinForm.touched.new_password && createPinForm.errors.new_password ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{createPinForm.errors.new_password}</div> : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                            <div className="form-group">
                                                <label className="form-label">Confirm New Pin <i className="fas fa-key me-1"></i></label>
                                                <div className="input-group">
                                                    <input type={showConfPassword ? "text" : "password"} className="form-control" placeholder="Enter Confirm Pin"
                                                        {...createPinForm.getFieldProps("confirm_password")} />
                                                    {showConfPassword ?
                                                        <span toggle="#password-field" onClick={() => setshowConfPassword(false)} className="fa fa-fw fa-eye field-icon toggle-password hideeyes me-3"></span>
                                                        :
                                                        <span toggle="#password-field" onClick={() => setshowConfPassword(true)} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes me-3"></span>
                                                    }
                                                </div>

                                                {createPinForm.touched.confirm_password && createPinForm.errors.confirm_password ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{createPinForm.errors.confirm_password}</div> : ''}
                                            </div>
                                        </div>
                                    </div>

                                </>
                            }
                            <div className="col-md-12 text-center">
                                {(IspinUpdated || IspinUpdated == false) && <button type="submit" className="btn btn-primary w-md me-2"> Submit <i className="fas fa-arrow-right me-1"></i></button>}
                                {IspinUpdated && <>
                                    {spinner ? <div className="btn btn-dark">Forget Password <Loader status={spinner} /></div> :
                                        <div className='btn btn-dark' onClick={() => CreateOtpFunction()}>Forget Password</div>
                                    }
                                </>
                                }
                            </div>
                        </div>
                    </form> : <>{IspinUpdated == "undefined" && <Loader />}</>}



                {forgetPin &&
                    <form className="row" id='registerForm' onSubmit={createOTPForm.handleSubmit}>
                        <div className="row d-flex justify-content-center mb-3">
                            <div className="col-md-12 text-bold" style={{ textTransform: 'none' }}>
                                <p className="text-center"><i className='fas fa-mobile-alt text-primary me-2' />Please check your registered mobile number for the OTP sent by us.</p>
                                {/* <p className="text-center"><i className='fa-solid fa-lock text-primary me-2' />Enter the OTP and create a new PIN to complete the verification process.</p> */}
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">OTP <i className="fas fa-mobile-alt me-1"></i></label>
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Enter OTP" {...createOTPForm.getFieldProps("verify_otp")} />
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-lock"></i></span>
                                    </div>
                                    {createOTPForm.touched.verify_otp && createOTPForm.errors.verify_otp ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{createOTPForm.errors.verify_otp}</div> : ''}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">New Pin <i className="fas fa-key me-1"></i></label>
                                    <div className="input-group">
                                        <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Enter New Pin" {...createOTPForm.getFieldProps("new_pin")} />
                                        {showPassword ?
                                            <span toggle="#password-field" onClick={() => setshowPassword(false)} className="fa fa-fw fa-eye field-icon toggle-password hideeyes me-3"></span>
                                            :
                                            <span toggle="#password-field" onClick={() => setshowPassword(true)} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes me-3"></span>
                                        }
                                    </div>
                                    {createOTPForm.touched.new_pin && createOTPForm.errors.new_pin ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{createOTPForm.errors.new_pin}</div> : ''}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Confirm New Pin <i className="fas fa-key me-1"></i></label>
                                    <div className="input-group">
                                        <input type={showConfPassword ? "text" : "password"} className="form-control" placeholder="Enter Confirm Pin"
                                            {...createOTPForm.getFieldProps("confirm_pin")} />
                                        {showConfPassword ?
                                            <span toggle="#password-field" onClick={() => setshowConfPassword(false)} className="fa fa-fw fa-eye field-icon toggle-password hideeyes me-3"></span>
                                            :
                                            <span toggle="#password-field" onClick={() => setshowConfPassword(true)} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes me-3"></span>
                                        }
                                    </div>

                                    {createOTPForm.touched.confirm_pin && createOTPForm.errors.confirm_pin ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{createOTPForm.errors.confirm_pin}</div> : ''}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-primary w-md me-2">Submit <i className="fas fa-arrow-right me-1"></i></button>
                            </div>
                        </div>
                    </form>}

            </div>
        </>
    )
}
export default ManagePin;
