import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getApi, postApi } from '../service/lenderService';
import moment from 'moment';


const Disbursedemi = () => {
    const id = useParams();
    const location = useLocation();
    const revised_emi = location?.state?.revised_emi;
    const require_loan_amount = location?.state?.require_loan_amount

    // console.log(require_loan_amount, "**************")
    const navigate = useNavigate();
    const currentDates = moment().format('YYYY-MM-DD')
    const [disbursedDetail, setDisbursedDetail] = useState({})

    const GetdisbursedDetail = async () => {
        try {
            const response = await getApi(`/get-disburse-details/${id.id}`);
            console.log('response', response);
            if (response.status) {
                setDisbursedDetail(response.data);
            }
        } catch (error) {
            console.error("🚀 ~ file: GetdisbursedDetail.js:12 ~  error:", error)
        }
    }

    useEffect(() => {
        if (revised_emi == "revised_emi") {
            GetdisbursedDetail();
        }
    }, []);



    const [state, setState] = useState({
        interest_amount: '0',
        overall_interest_amount: '0',
        total_repayment_amount: '0',
        repayment_amount: '0'
    });

    // console.log(require_loan_amount, disbursedDetail?.amount)

    const AddForm = useFormik({
        initialValues: {
            loan_status_id: "4",
            repayment_type: disbursedDetail?.repayment_type ? disbursedDetail?.repayment_type : "",
            amount: disbursedDetail?.amount ? disbursedDetail?.amount : require_loan_amount ? require_loan_amount : "",
            lender_roi: disbursedDetail?.lender_roi ? disbursedDetail?.lender_roi : "",
            total_repayment_number: disbursedDetail?.total_repayment_number ? disbursedDetail?.total_repayment_number : "",
            currency: disbursedDetail?.currency ? disbursedDetail?.currency : "",
            repayment_start_date: disbursedDetail?.repayment_start_date ? disbursedDetail?.repayment_start_date : "",
            comment: disbursedDetail?.comment ? disbursedDetail?.comment : "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            repayment_type: yup.string().required('Please Select Repayment Type'),
            amount: yup
                .number()
                .typeError('Please Enter a valid Amount')
                .required('Please Enter Disburse Amount')
                .min(1, 'Amount must be greater than 0')
                .test('len', 'Amount must be at most 10 digits', val => {
                    return val === 0 || (val && val.toString().length <= 10);
                })
                .test('max', `Amount must not be greater than ${require_loan_amount ? require_loan_amount : disbursedDetail?.amount}`, val => {
                    const maxAmount = require_loan_amount || parseFloat(disbursedDetail?.amount);
                    return val <= maxAmount;
                }),
            // amount: yup
            //     .number()
            //     .typeError('Please Enter a valid Amount')
            //     .required('Please Enter Disburse Amount')
            //     .min(1, 'Amount must be greater than 0')
            //     .test('len', 'Amount must be at most 10 digits', val => {
            //         return val === 0 || (val && val.toString().length <= 10);
            //     })
            //     .test('max', `Amount must not be greater than ${require_loan_amount ? require_loan_amount : disbursedDetail?.amount}`, val => {
            //         return val <= require_loan_amount ? require_loan_amount : parseFloat(disbursedDetail?.amount);
            //     })
            // ,
            lender_roi: yup.string().required('Please Enter Rate of Interest')
                .matches(/^\d+(\.\d+)?$/, 'Invalid input. Please enter a decimal number'),
            total_repayment_number: yup.number().required('Please Enter Total Repayments')
                .min(1, 'Repayments must be greater than 0')
                .test('integer', 'Invalid input. Please enter a whole number', (value) => {
                    if (value === undefined || value === '') return true;
                    const regex = /^\d+$/;
                    return regex.test(String(value));
                }),
            currency: yup
                .string()
                .required('Please select Currency'),
            comment: yup.string().required('Please Enter Comment'),
            repayment_start_date: yup.string()
                .test('futureDate', 'Date must be on or after the current date', (value) => {
                    return moment(value).isSameOrAfter(currentDates);
                }).required('Please Select Repayment Due Date'),

        }),

        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("loan_status_id", "4");
            formData.append("repayment_type", AddForm.getFieldProps('repayment_type').value);
            formData.append("amount", AddForm.getFieldProps('amount').value);
            formData.append("total_repayment_number", AddForm.getFieldProps('total_repayment_number').value);
            formData.append("repayment_amount", state?.repayment_amount);
            formData.append("currency", AddForm.getFieldProps('currency').value);
            formData.append("lender_roi", AddForm.getFieldProps('lender_roi').value);
            formData.append("interest_amount", state?.interest_amount);
            formData.append("overall_interest_amount", state?.overall_interest_amount);
            formData.append("total_repayment_amount", state?.total_repayment_amount);
            formData.append("repayment_start_date", AddForm.getFieldProps('repayment_start_date').value);
            formData.append("comment", AddForm.getFieldProps('comment').value);
            const response = await postApi((revised_emi == "revised_emi" ? `/update-disburse-details/${id.id}` : `/update-loan-status/${id.id}`), formData, true)
            if (response.status) {
                toast.success(response.message);
                navigate(process.env.REACT_APP_LENDER_PRIFIX + '/manage_ewa_advance');
                resetForm({ values: "" });
            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    });



    // console.log(require_loan_amount, "*********************", disbursedDetail?.amount)
    // console.log(require_loan_amount ? require_loan_amount : parseFloat(disbursedDetail?.amount), "**************")

    useEffect(() => {
        const calculateInterest = () => {
            const interest_amount = (AddForm.values.amount * AddForm.values.lender_roi) / 100;
            const overall_interest_amount = interest_amount * AddForm.values.total_repayment_number;
            const total_repayment_amount = parseInt(AddForm.values.amount) + overall_interest_amount;
            const repayment_amount = total_repayment_amount / AddForm.values.total_repayment_number;
            setState({
                interest_amount: interest_amount.toFixed(2),
                overall_interest_amount: overall_interest_amount.toFixed(2),
                total_repayment_amount: overall_interest_amount == '0' ? "0" : total_repayment_amount.toFixed(2),
                repayment_amount: repayment_amount.toFixed(2)
            });
        };

        calculateInterest();
    }, [AddForm.values.amount, AddForm.values.lender_roi, AddForm.values.total_repayment_number]);

    const currencies = [
        { code: 'USD', name: 'US Dollar' },
        { code: 'INR', name: 'Indian Rupee' },
        { code: 'AUD', name: 'Australian Dollar' },
        { code: 'NZD', name: 'New Zealand Dollar' },
        { code: 'FJD', name: 'Fijian Dollar' },
    ];




    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-0">
                    <div className="page-leftheader">
                        {/* <h4 className="page-title mb-0 text-primary">Add Employee</h4> */}
                        <h4 className="page-title mb-0 text-primary">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/lender/manage_ewa_advance" >Manage EWA Advance</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add {revised_emi == "revised_emi" ? "Revised Repayment detail" : "Repayment detail"} </li>
                                </ol>
                            </nav>
                        </h4>
                    </div>
                </div>
                <form id='registerForm' onSubmit={AddForm.handleSubmit}>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                            <label className="form-label">Disburse Amount</label>
                            <input type="number" className="form-control" placeholder="Enter Amount"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => {
                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                        e.preventDefault();
                                    }
                                }}
                                {...AddForm.getFieldProps("amount")} />
                            {AddForm.touched.amount && AddForm.errors.amount ?
                                <div className="invalid-feedback mb-2" style={{ display: "block" }}>{AddForm.errors.amount}</div> : ''}
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                            <label className="form-label">Repayment Start Date</label>
                            <input type="date" className="form-control" min={currentDates} placeholder="Enter Repayment Start Date" {...AddForm.getFieldProps("repayment_start_date")} />

                            {AddForm.touched.repayment_start_date && AddForm.errors.repayment_start_date ?
                                <div className="invalid-feedback mb-2" style={{ display: "block" }}>{AddForm.errors.repayment_start_date}</div> : ''}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xm-12">
                            <label className="form-label">Comment</label>
                            <input type="text" className="form-control" placeholder="Enter Comment" {...AddForm.getFieldProps("comment")} />
                            {AddForm.touched.comment && AddForm.errors.comment ?
                                <div className="invalid-feedback mb-2" style={{ display: "block" }}>{AddForm.errors.comment}</div> : ''}
                        </div>


                        {/************************* FOR WEEKLY EMI INPUT FIELDS START **********************/}

                        <div className="row py-4" style={{ border: '1px solid #babcc1' }} >
                            {/* <div className='card-body container row ms-1' > */}
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <label className="form-label">Repayment Type</label>
                                <select className="form-control mb-0" /* name='submit' */  {...AddForm.getFieldProps('repayment_type')}>
                                    <option>Select Status</option>
                                    <option value="WEEKLY" >Weekly</option>
                                    <option value="FORTNIGHTLY"  >Fortnightly</option>
                                    <option value="MONTHLY" >Monthly</option>

                                </select>
                                {AddForm.touched.repayment_type && AddForm.errors.repayment_type ?
                                    <div className="invalid-feedback mb-2" style={{ display: "block" }}>{AddForm.errors.repayment_type}</div> : ''}
                                {/* <input className="form-control text-white text-center" style={{ backgroundColor: '#0f2f82' }} value={'WEEKLY'} disabled /> */}
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <label className="form-label">Rate of Interest</label>
                                <input type="text" className="form-control" placeholder="Enter Rate of Interest"
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                            e.preventDefault();
                                        }
                                    }}
                                    {...AddForm.getFieldProps("lender_roi")} />

                                {AddForm.touched.lender_roi && AddForm.errors.lender_roi ?
                                    <div className="invalid-feedback mb-2" style={{ display: "block" }}>{AddForm.errors.lender_roi}</div> : ''}
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <label className="form-label">Total Repayment</label>
                                <input type="number" className="form-control" placeholder="Enter Total Repayment"
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                            e.preventDefault();
                                        }
                                    }}
                                    {...AddForm.getFieldProps("total_repayment_number")} />

                                {AddForm.touched.total_repayment_number && AddForm.errors.total_repayment_number ?
                                    <div className="invalid-feedback mb-2" style={{ display: "block" }}>{AddForm.errors.total_repayment_number}</div> : ''}
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <label className="form-label">Plan Currency</label>
                                <select className="form-control" {...AddForm.getFieldProps('currency')}>
                                    <option value=''>Please Select Currencies </option>
                                    {currencies.map(currency => (
                                        <option key={currency.code} value={currency.code}>
                                            {/* {currency.code} - */} {currency.name}
                                        </option>
                                    ))}
                                </select>
                                {AddForm.touched.currency && AddForm.errors.currency ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.currency}</div> : ''}
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <label className="form-label">Interest Amount</label>
                                <input type="number" className="form-control" placeholder="View Interest Amount"
                                    value={state.interest_amount} disabled />
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <label className="form-label">Overall Interest Amount</label>
                                <input type="number" className="form-control" placeholder="Overall Interest Amount"
                                    value={state.overall_interest_amount} disabled />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <label className="form-label"> Repayment</label>
                                <input type="number" className="form-control" placeholder="View Repayment"
                                    value={state.repayment_amount} disabled />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <label className="form-label"> Total Amount</label>
                                <input type="number" className="form-control" placeholder="View Total Amount"
                                    value={state.total_repayment_amount} disabled />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12" />
                            {/* </div> */}
                        </div>
                        {/************************* FOR WEEKLY EMI INPUT FIELDS END **********************/}




                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Receipt </label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control browse-file"
                                            name={logoValue} placeholder="Upload Receipt" disabled />
                                        <label className="input-group-text btn btn-primary"> Browse
                                            <input type="file" className="file-browserinput" onChangeCapture={uploadLogoFunc}
                                                onChange={(e) => imageUploads(e)} style={{ display: 'none' }} multiple /> </label>
                                        {AddForm.touched.image && AddForm.errors.image ?
                                            <div className="invalid-feedback mb-2" style={{ display: "block" }}>{AddForm.errors.image}</div> : ''}
                                    </div>
                                </div>
                                {logoValue && logoValue.name ? <ul className="imgpreview-newbx">
                                    <li className="registerboximg">
                                        <img src={URL.createObjectURL(logoValue)} alt="" />
                                        <Link to="" onClick={() => HandleRemoveImage()} className="upload__img-close"><i className="fa-solid fa-close fa-fw"></i></Link>
                                        <p>img preview name here</p>
                                    </li>
                                </ul> : ""}
                            </div>
                        </div> */}
                    </div>
                    <div className="my-2">
                        <div className="col-md-12">
                            <input type="submit" name="next" className="btn btn-primary ml-00" value="Submit" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Disbursedemi