import React, { useEffect, useRef, useState } from 'react'
import { GetBorrowerAdmin, getApi, postApi } from '../service/adminService';
import PaginationComponent from '../../PaginationComponent';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader/loader';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import { Switch } from 'antd';
import toast from 'react-hot-toast';
import Search from '../../components/Search/Search';

const Borrower = () => {
    const [ValidPagination, setValidPagination] = useState(false)
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [dataArray, setDataArray] = useState([]);
    const [employerData, setemployerData] = useState([]);
    const [employeeDataID, setemployeeDataID] = useState("")
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [Count, setCount] = useState();
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const tableRef = useRef(null);
    const admin = JSON.parse(localStorage.getItem("admin"));
    // const token = admin.admin_token
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)

    const GetBorrowerAdminData = async (token) => {
        const response = await GetBorrowerAdmin(token, pagesCount, search, sortedBy, orderBy, employeeDataID, date_Format);
        // const response = await getApi("/get-borrower-list", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy, employer_id: employeeDataID, time_zone: date_Format });
        console.log("🚀 ~ file: Employee.js:111 ~ getEmployeesData ~ response:", response)
        if (response.status) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.AllEmployee);
                setCount(response.AllEmployee);
                setloader(false)
            }, 2000);
            setloader(true)
            // setDataArray(response.data);
        } else {
            console.log("get employees data response", response);
        }
    }

    // Capitalize = (str) => {
    //     return str.charAt(0).toUpperCase() + str.slice(1);
    //     }
    // console.log(date_Format, "date_Format")

    useEffect(() => {

        GetBorrowerAdminData(admin.admin_token, pagesCount, search);
    }, [search, pagesCount, sortedBy, orderBy, employeeDataID]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // GetBorrowerAdminData(admin.admin_token, pagesCount, search );
    }

    const getEmployeesDatas = async (token) => {
        // const response = await GetEmployersdatas(token);
        const response = await getApi("/get-employer-list");
        // console.log(response.data)
        if (response.status) {
            setemployerData(response.data);
        } else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getEmployeesDatas(admin.admin_token);
    }, []);

    const handleChangeQuery = (e) => {
        // console.log(e.target.value)
        setemployeeDataID(e.target.value)

    }
    const [toggle, setToggle] = useState(true)


    const handlechangeToggle = async (id) => {

        try {
            // const response = await borrowerStatus(token, id)
            const response = await getApi(`/manage-borrower-active-status/${id}`)
            if (response.status == true) {
                // console.log(response)
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
        } catch (error) {

            console.log(error)
        }
    }

    const handlechangeEWAStatus = async (id) => {

        try {
            // const response = await borrowerStatus(token, id)
            const response = await getApi(`/update-ewa-status/${id}`)
            if (response.status == true) {
                // console.log(response)
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
        } catch (error) {

            console.log(error)
        }
    }

    // console.log(pagesCount, "pagesCount_______________")

    return (
        <>
            {!showAddNewCases &&
                <div className="main-container container-fluid px-0">
                    <div className="page-header mb-3">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">Borrower {Count ? "(" + Count + ")" : ""}</h4>
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                {/* <Link to="" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Borrower</Link> */}
                                <Link to="/admin/borrower_onboarding" className="btn btn-primary btn-pill mt-2"><i className="fa fa-plus me-2"></i> Borrower Onboarding</Link>
                                <DownloadTableExcel
                                    filename="users table"
                                    sheet="users"
                                    currentTableRef={tableRef.current}>
                                    <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                </DownloadTableExcel>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" >
                                <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group"> */}
                        {/* <select className="form-control mb-0" name="status"
                                                  value={queryFilter.filter} onChange={(e) => handleChangeQueryBuilder(e)} 
                                                 > */}
                        {/* <option>Select Status</option>
                                                    <option value="all">All Status</option>
                                                    <option value="Assigned">Assigned</option>
                                                    <option value="Unassigned">Unassigned</option> */}
                        {/* </select> */}
                        {/* </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Select Employer</label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="loan_type" /* value={queryFilter.loan_type}*/ onChange={(e) => handleChangeQuery(e)} >
                                                <option value="">Select Employer</option>
                                                {employerData.map(item => (<option className='text-dark' key={item.id} value={item.user_id}>
                                                    {item?.company_name ? item.company_name : ""}
                                                    {item?.company_name && item?.mobile_number ? " - " : ""}
                                                    {item?.mobile_number ? <span className='text-primary'>{item.mobile_number}</span> : ""}
                                                    {item?.company_name && item?.mobile_number ? <br /> : ""}
                                                    {!(item?.company_name && item?.mobile_number) && (
                                                        <span> {item?.email ? item.email : "--"} </span>
                                                    )}
                                                    <hr />
                                                </option>))}
                                                {employerData.length == 0 &&
                                                    <option className='text-bold'>No Employer Available</option>

                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12"></div> */}
                        {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={() => setpagesCount(1)} />
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold"> Borrower {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                    {/* <th className="font-weight-bold">Mobile No.     
                                                        {sortedBy =="mobile_number" && orderBy==="desc" ? <i className='fas fa-long-arrow-alt-up' style={{fontSize : 10}} onClick={()=>{sortChange('mobile_number')}} ></i> :<i className='fas fa-long-arrow-alt-down' style={{fontSize : 10}} onClick={()=>{sortChange('mobile_number')}}></i>}</th> */}
                                                    <th className="font-weight-bold">Employer {sortedBy == "employer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }}></i>}</th>
                                                    {/* <th className="font-weight-bold">Limit    {sortedBy == "total_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_limit') }}></i>} </th> */}
                                                    <th className="font-weight-bold">BNPL Limit    {sortedBy == "availableBnplLimit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('availableBnplLimit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('availableBnplLimit') }}></i>} </th>
                                                    <th className="font-weight-bold">BNPL Used Limit    {sortedBy == "usedBnplLimit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('usedBnplLimit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('usedBnplLimit') }}></i>} </th>
                                                    <th className="font-weight-bold">EWA Limit    {sortedBy == "availableEWABalance" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('availableEWABalance') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('availableEWABalance') }}></i>} </th>
                                                    <th className="font-weight-bold">EWA used Limit    {sortedBy == "usedEWABalance" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('usedEWABalance') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('usedEWABalance') }}></i>} </th>
                                                    <th className="font-weight-bold">Wallet Limit    {sortedBy == "availableWalletBalance" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('availableWalletBalance') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('availableWalletBalance') }}></i>} </th>

                                                    <th className="font-weight-bold">Wallet Used Limit    {sortedBy == "usedWalletBalance" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('usedWalletBalance') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('usedWalletBalance') }}></i>} </th>

                                                    <th className="font-weight-bold">EWA Agreement</th>

                                                    <th className="font-weight-bold">EWA Status</th>

                                                    <th className="font-weight-bold">Country  {sortedBy == "country" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('country') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('country') }}></i>}</th>

                                                    <th className="font-weight-bold">Status</th>

                                                    <th className="font-weight-bold">Created Date  {sortedBy == "createdAt" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }}></i>}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /><td /><td /><td />
                                                <Loader /></tbody> : <>
                                                <tbody>
                                                    {dataArray.length > 0 && dataArray.map((option, index) => {
                                                        let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                        const { customer_id, mobile_number, first_name, last_name, personal_email, company_name, employer_name, employer_mobile, country, availableBnplLimit, usedBnplLimit, availableEWABalance, usedEWABalance, usedWalletBalance, availableWalletBalance, ewa_agreement_doc, ewa_status } = option;
                                                        // console.log(option?.first_name?.charAt(0).toLowerCase())
                                                        // var Capitalize = (option) => {
                                                        //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                        // }
                                                        return (
                                                            <tr key={serial_num}>
                                                                {/* <td><span className="font-weight-normal1">{serial_num}</span></td> */}
                                                                <td> <Link to={{ pathname: `${prefix}/employeedetails/${customer_id}`, state: { customer_id } }} >
                                                                    <span className='font-weight-normall text-primary'>{option.first_name ? first_name : ""} {option.last_name ? last_name : ""}
                                                                        <div>{mobile_number}</div>
                                                                        <div style={{ textTransform: 'none' }}>{personal_email}</div>
                                                                    </span> </Link>  </td>
                                                                <td>   {option?.company_name || option?.employer_mobile || option?.email ? <Link className="text-primary" to={prefix + `/employer_detail/${option.employer_id}`}>
                                                                    <span className="font-weight-normal1">{option.company_name ? company_name : "--"}<div >{employer_mobile ? employer_mobile : ""}</div><div style={{ textTransform: 'none' }}>{option?.email && option.email}</div></span></Link> :
                                                                    <span>--</span>}</td>
                                                                {/* <td><span className="font-weight-normal1">{option.total_limit ? "$" + total_limit : "-"}</span></td> */}
                                                                <td><span className="font-weight-normal1">{option.availableBnplLimit !== null ? "$" + Number(availableBnplLimit).toFixed(2) : "0.00"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.usedBnplLimit !== null ? "$" + Number(usedBnplLimit).toFixed(2) : "0.00"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.availableEWABalance !== null ? "$" + Number(availableEWABalance).toFixed(2) : "0.00"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.usedEWABalance !== null ? "$" + Number(usedEWABalance).toFixed(2) : "0.00"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.availableWalletBalance !== null ? "$" + Number(availableWalletBalance).toFixed(2) : "0.00"}
                                                                </span></td>
                                                                <td><span className="font-weight-normal1">{option.usedWalletBalance !== null ? "$" + Number(usedWalletBalance).toFixed(2) : "0.00"}</span></td>
                                                                {ewa_agreement_doc
                                                                    ? <td className="text-center">
                                                                        <a href={ewa_agreement_doc} download>
                                                                            <img
                                                                                src="/assets/img/pdf.jpeg"
                                                                                style={{ width: '20px', height: '25px', border: '1px solid silver' }}
                                                                                className="card-img-top m-1"
                                                                                alt="PDF"
                                                                            />
                                                                        </a>
                                                                    </td>
                                                                    : <td>
                                                                        <div className="text-center">
                                                                            -- --
                                                                        </div>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    {ewa_status == '1'
                                                                        ? <div className="col-auto ms-auto d-print-none">
                                                                            <Switch checkedChildren="Active" onClick={() => handlechangeEWAStatus(option.customer_id)} unCheckedChildren="Blocked" defaultChecked onChange={(e) => setToggle(e)} />
                                                                        </div> : ""}
                                                                    {ewa_status != '1' ? <div className="col-auto ms-auto d-print-none">
                                                                        <Switch checkedChildren="Active" onClick={() => handlechangeEWAStatus(option.customer_id)} unCheckedChildren="Blocked" onChange={(e) => setToggle(e)} />
                                                                    </div> : ""}
                                                                </td>
                                                                <td><span className="font-weight-normal1">{option.country ? country : ""}</span></td>
                                                                <td> {option.user_active_status == '1' ?
                                                                    <div className="col-auto ms-auto d-print-none">
                                                                        <Switch checkedChildren="Active" onClick={() => handlechangeToggle(option.customer_id)} unCheckedChildren="Blocked" defaultChecked onChange={(e) => setToggle(e)} />
                                                                    </div> : ""
                                                                }
                                                                    {option.user_active_status == '0' ?
                                                                        <div className="col-auto ms-auto d-print-none">
                                                                            <Switch checkedChildren="Active" onClick={() => handlechangeToggle(option.customer_id)} unCheckedChildren="Blocked" onChange={(e) => setToggle(e)} />
                                                                        </div> : ""
                                                                    }
                                                                </td>

                                                                <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                <td><Link to={{ pathname: `${prefix}/borrower/requestlogs_by_id/${customer_id}`, state: { customer_id } }} className="btn btn-primary btn-pill">View Logs</Link></td>
                                                                {/* <td><span className="font-weight-normal1">{bnpl_status ? Capitalize(bnpl_status): ""}</span></td> */}
                                                                {/* <td> <Link to="" className="btn btn-primary "> Action</Link> */}
                                                                {/* <Dropdown>
                                                                        <Dropdown.Toggle className='view-pro' id="dropdown-basic">
                                                                            ACTION
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>                                                                         
                                                                            <Dropdown.Item>
                                                                                {status == 'Unassigned' ?
                                                                                   
                                                                                    <Link to={prefix + `/employee_setlimit/${customer_id}/${status}`}> <div style={{width:"100%"}}>Set Limit</div></Link>
                                                                                    :
                                                                                    <Link to={prefix + `/employee_setlimit/${customer_id}/${status}`}><div style={{width:"100%"}}>Manage Limit</div></Link>
                                                                                }
                                                                            </Dropdown.Item>
                                                                           
                                                                        </Dropdown.Menu>
                                                                    </Dropdown> */}
                                                                {/* </td> */}



                                                            </tr>)
                                                    }
                                                    )}
                                                </tbody><tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                        </tr>}
                                                </tbody>
                                            </>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                        {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>
                </div>


            }

        </>
    )
}

export default Borrower
