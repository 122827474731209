import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import toast from 'react-hot-toast';
import PaginationComponent from '../../../PaginationComponent';
import { GetPermission, getApi } from '../../service/lenderService';
import Loader from '../../../components/Loader/loader';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import Search from '../../../components/Search/Search';
const prefix = process.env.REACT_APP_LENDER_PRIFIX;

const Employee = (props) => {
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [ValidPagination, setValidPagination] = useState(false)
    // const [endDate, setendDate] = useState('');
    // const [startDate, setstartDate] = useState('');
    const tableRef = useRef(null);
    // const [statusdrop, setStatusdrop] = useState('')
    const [sortedBy, setSortedBy] = useState("user_id")
    const [orderBy, setOrderBy] = useState('desc')
    const navigate = useNavigate();
    const [loader, setloader] = useState(true)
    const lender = JSON.parse(localStorage.getItem("lender"));

    const getEmployeesData = async () => {
        const response = await getApi(`/all-submit-form-employee`, { filter: "Assigned", search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
        if (response.status) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.allEmployee)
                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        } else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        getEmployeesData()
    }, [search, pagesCount, sortedBy, orderBy]);

    const [permission_data, setPermission_data] = useState([])

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            // console.log('response', response);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }
    const user_type = lender.user_type
    useEffect(() => {
        if (user_type == "SUB-LENDERS") {
            GetPermissions(token);
        }
    }, []);

    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);
    } else {

    }
    const [existing_employee_view, setexisting_employee_view] = useState(false)
    const [existing_employee_limit, setexisting_employee_limit] = useState(false)

    useEffect(() => {
        if (role) {
            const options = role;
            console.log(options)
            options.length > 0 && options.forEach((option) => {
                if (option.key == "existing_employee_view") {
                    setexisting_employee_view(true);
                }
                if (option.key == "existing_employee_limit") {
                    setexisting_employee_limit(true);
                }

            });
        }
    }, [role]);


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }


    // const handleNavigate = (option) => {
    //     navigate(`${prefix}/manage_pin/${option.customer_id}`, { state: { option } });
    // };


    return (
        <>
            {!showAddNewCases &&
                <div className="main-container container-fluid px-0">
                    <div className="page-header mb-3">
                        <div className="page-leftheader">
                            <h6 className="page-title mb-0 text-primary ">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Borrower</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Existing Borrower</li>
                                    </ol>
                                </nav>
                            </h6>
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                {/* <Link to="" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Borrower</Link> */}
                                <DownloadTableExcel
                                    filename="users table"
                                    sheet="users"
                                    currentTableRef={tableRef.current}>
                                    <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                </DownloadTableExcel>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                        {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={() => setpagesCount(1)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">Borrower Name {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                    <th className="font-weight-bold">Employer Name {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>}</th>
                                                    <th className="font-weight-bold">Total Limit  {sortedBy == "total_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_limit') }}></i>}</th>
                                                    <th className="font-weight-bold">Available Limit {sortedBy == "available_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('available_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('available_limit') }}></i>}</th>
                                                    <th className="font-weight-bold">Used Limit {sortedBy == "used_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('used_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('used_limit') }}></i>}</th>
                                                    <th className="font-weight-bold">Created Date  {sortedBy == "createdAt" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }}></i>}</th>
                                                    {(existing_employee_limit || user_type == 'lenders') && <th className="font-weight-bold">Action</th>}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /><td /><td />
                                                <Loader />
                                            </tbody> :
                                                <>
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            const { customer_id, mobile_number, first_name, personal_email, created_at, status, available_limit, total_limit, used_limit, company_name, name, employer_mobile, employer_id } = option;
                                                            return (
                                                                <tr key={serial_num} >
                                                                    <td>
                                                                        <Link className="text-primary" to={{ pathname: `${prefix}/employee_detail/${mobile_number}/Existing`, state: { mobile_number } }}>
                                                                            {/* <div className="text-primary" style={{ cursor: 'pointer' }} onClick={() => handleNavigate(option)}> */}

                                                                            <span className="font-weight-normal1">{first_name ? first_name : ""}<br />{mobile_number}<br /><span style={{ textTransform: 'none' }}>{personal_email}</span></span></Link></td>
                                                                    <td><Link className="text-primary text-primary" to={{ pathname: `${prefix}/employer_detail/${employer_id}/Existing`, state: { employer_id } }}><span className="font-weight-normal1">{company_name ? company_name : ""}<br />{employer_mobile}</span></Link></td>
                                                                    <td><span className="font-weight-normal1">{option.total_limit ? "$" + total_limit : 0.00}</span></td>
                                                                    <td><span className="font-weight-normal1">{option.available_limit ? "$" + available_limit : 0.00}</span></td>
                                                                    <td>
                                                                        {/* <Link className="text-primary" to={{ pathname: `${prefix}/usedlimitdetails/${customer_id}/Existing` }}> */}
                                                                        <span className="font-weight-normal1" >{option.used_limit ? "$" + used_limit : 0.00}</span>
                                                                        {/* </Link> */}
                                                                    </td>
                                                                    <td><span className="font-weight-normal1">{/* <Moment format="YYYY-MM-DD "> */}{TimeZone(created_at).timeZone}</span></td>
                                                                    {(existing_employee_limit || user_type == 'lenders') && (<td><Link className='btn btn-primary btn-pill' to={prefix + `/employee_setlimit/${customer_id}/Existing`}> <span className="font-weight-normal1">Manage Limit</span></Link></td>)}
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Existing Data Available</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                        {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>
                </div>
            }

        </>
    )
}
export default Employee;
