
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import Header from './layout/header'
import Footer from './layout/footer'
import Service from './../adminService';
import Dashboard from './dashboard';
import Lender from './lender';
import EditLender from './lender/editLender';
// import Cases from './cases';
// import CasesDetail from './cases/detail';
import Profile from './profile';
import AddEditProfile from './profile/addEditProfile';
import Users from './users';
import AddEditUser from './users/addEditUser';
import UserReport from './userReport/index';
import AddEditUsersReport from './userReport/editUser';
import QueryBuilder from './querybuilder';
import LoginLog from './common/loginlog';
import Notification from './common/notification';
import State from './setting/state';
import City from './setting/city';
import Pincode from './setting/pincode';
import Status from './setting/status';
import EditCity from './setting/editCity';
import AdminProfile from './setting/adminprofile';
import ChangePassword from './setting/changepassword';
import Employer from './Employer/Employer';
import EmployeeDetail from '../admin/Employer/EmployerDetail';
import AllEmployeeOfEmployer from '../admin/Employer/AllEmployee'
import { UseAppContext } from '../Context/AppContext';
import Merchant from './Merchant/Merchant';
import Settlement from './settlement/settlement';
import PendingSettlement from './pendingSettlement/pendingSettlement';
import AddSettlement from './pendingSettlement/addSettlement';
import Employee from './Directusers/Directusers';
import Directusers from './Directusers/Directusers';
import Borrower from './Borrower/Borrower';
import EmployeeDetails from './Borrower/EmployeeDetails';
// import Planlist from './SettingplanList/planlist';
import Country from './SettingplanList/Country';
import LenderCommission from './SettingplanList/LenderCommission'
import MerchantCommission from './SettingplanList/MerchantCommission'
import EditEmiplan from './SettingplanList/EditEmiplan';
import LenderEmployeedetail from './lender/lenderEmployeedetail';
import EmployeeEmidetails from './lender/employeeEmidetails';
import Privaypolicy from './Privacy policy/privacypolicy';
import LenderInterest from './LenderInterest/lenderInterest';


import '../assets/css/bootstrap.min.css';
import '../assets/css/style.css';
import '../assets/css/custom.css';
import '../assets/css/animated.css';
import Talktous from './Talk_To_Us/talktous';
import Firststep from './Borrower/Borroweronboarding/firststep';
import Merchantdues from './ManageEWA/merchantdues';
import Merchantpaid from './ManageEWA/merchantpaid';
import Merchantsettlement from './ManageEWA/merchantsettlement';
import ViewdetailbyID from './ManageEWA/ViewdetailbyID';
import AddEmployer from './Employer/AddEmployer';
import AddLender from './lender/AddLender';
import Addmerchant from './Merchant/Addmerchant';
import EditEmployer from './Employer/EditEmployer';
import EditMerchant from './Merchant/EditMerchant';
import BNPLsettlement from './BNPLtransaction/BNPLsettlement';
import BNPLtransaction from './BNPLtransaction/BNPLtransaction';
import SettlementById from './BNPLtransaction/SettlementById';
import TransactionById from './BNPLtransaction/TransactionById';
import EmployeeLogs from './Logs/Employee/EmployeeLogs';
import UserLoginlog from './Logs/UsersLog/UserLoginlog';
import Viewreconciliation from './ManageEWA/ViewReconciliation';
import EwaEarning from './EarningReport/EwaEarning';
import BnplEarning from './EarningReport/BnplEarning';
import EwaEarningdetail from './EarningReport/EwaEarningdetail';
import Employeealltxn from './Alltransactions/Employeetxn';
import Managepromotion from './Promotion/Managepromotion';
import ViewPending from './ManageWallet/ViewPending';
import TxnSettlement from './ManageWallet/TxnSettlement';
import ViewPaid from './ManageWallet/ViewPaid';
import ViewPaidbyId from './ManageWallet/ViewPaidbyId';
import AttendanceErrorlogs from './Logs/AttendanceErrorlogs';
import EwaAgree from './EWA Agree/EwaAgree';
// import Loanapplication from './LoanApplication/Loanapplication';
// import ApplicantDetails from './LoanApplication/ApplicantDetail/ApplicantDetails';
import Subemployerdetail from './Employer/Subemployerdetail';
import Sublenderdetail from './lender/Sublenderdetail';
import Store from './Merchant/Store';
import Counter from './Merchant/Counter';
import Trash from './Employer/Trash';
import Lendertrash from './lender/Lendertrash';
import Merchanttrash from './Merchant/Merchanttrash';
import Cashback from './CashBack/Cashback';
import Cashbackbyid from './CashBack/CasbackbyID';
import Merchantdetail from './Merchant/Merchantdetail';
import Banktransfer from './Bank Transafer/Banktransfer';
import LenderDetail from './lender/LenderDetail';
import TransferRequest from './TransferRequest/transferRequest';
import Balancetransfer from './SettingplanList/Balancetransfer';
import ViewInvestment from './Manage Investment/ViewInvestment';
import InvestorSetting from './SettingplanList/InvestorSetting';
import Viewplan from './Manage EWA Plan/Viewplan';
import ViewplanDetail from './Manage EWA Plan/ViewplanDetail';
import Request from './Logs/Request';
import Requestbyid from './Logs/Requestbyid';
import Requestlogsbyid from './Borrower/Requestlogsbyid';
import EWAplateformcharges from './Manage EWA Plan/EWAplateformcharges';
import MonthlyEWAdeduction from './Manage EWA Plan/MonthlyEWAdeduction';
import DeductionErrorlogs from './Manage EWA Plan/DeductionErrorlogs';
// import Planlist from './Manage EWA Plan/WFM Plan/stripePlan';
import PlanHistory from './Employer/planHistory';
import Pf from './Manage EWA Plan/WFM Plan/Pf';
import StripePlan from './Manage EWA Plan/WFM Plan/stripePlan';
import TaxSlab from './Manage EWA Plan/WFM Plan/Manage Tax Slab/TaxSlab';
import TaxSlabByID from './Manage EWA Plan/WFM Plan/Manage Tax Slab/taxSlabByID';
import EwaAdvanceapplication from './EwaAdvanceApplication/ewaAdvanceapplication';
import ManageSMS from './ManageSMS/manageSMS';
import ManagePin from './EwaAdvanceApplication/ManagePin/managePin';

const api = new Service();
const Index = (props) => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const { profile_id, user_id, caseStatus, dsa_id, lender_id, id } = useParams();
    // const navigate = useNavigate();
    const { header } = UseAppContext();
    const [locationName, setlocationName] = useState('');
    const location = useLocation();

    useEffect(() => {
        setlocationName(location.pathname)
    }, [location.pathname])


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <div className="page">
                <div className="page-main">
                    <Header prefix={prefix} locationName={locationName} profile_id={profile_id} caseStatus={caseStatus} dsa_id={dsa_id} lender_id={lender_id} user_id={user_id} />

                    <div className={header ? "app_content_res app-content main-content" : "app-content main-content"}>
                        <div className="side-app">
                            {locationName == prefix + '/dashboard' || locationName == prefix ?
                                <Dashboard api={api} toast={toast} prefix={prefix} />
                                // : locationName == prefix + '/dsa' ?
                                //     <Dsa api={api} toast={toast}  prefix={prefix} user_id={user_id} />
                                //     : locationName == prefix + '/edit_dsa/' + user_id ?
                                // <EditDsa api={api} toast={toast}  prefix={prefix} />
                                : locationName == prefix + '/users' ?
                                    <UserReport api={api} toast={toast} prefix={prefix} user_id={user_id} />
                                    // : locationName == prefix + '/loan_application' ?
                                    //     <Loanapplication api={api} toast={toast}  prefix={prefix} />
                                    : locationName == prefix + '/edit_users/' + user_id ?
                                        <AddEditUsersReport api={api} toast={toast} prefix={prefix} />
                                        : locationName == prefix + '/employer' ?
                                            <Employer api={api} toast={toast} prefix={prefix} />
                                            : locationName == prefix + '/employer_detail/' + id ?
                                                <EmployeeDetail api={api} toast={toast} prefix={prefix} />
                                                : locationName == prefix + '/allemployee_of_employer/' + id ?
                                                    <AllEmployeeOfEmployer api={api} toast={toast} prefix={prefix} />
                                                    : locationName == prefix + '/lender' ?
                                                        <Lender api={api} toast={toast} prefix={prefix} />
                                                        : locationName == prefix + '/lenderemployeedetail/' + id ?
                                                            <LenderEmployeedetail api={api} toast={toast} prefix={prefix} />
                                                            : locationName == prefix + '/employeeEmidetails/' + id ?
                                                                <EmployeeEmidetails api={api} toast={toast} prefix={prefix} />
                                                                : locationName == prefix + '/merchant' ?
                                                                    <Merchant api={api} toast={toast} prefix={prefix} />
                                                                    : locationName == prefix + '/edit_lender/' + user_id ?
                                                                        <EditLender api={api} toast={toast} prefix={prefix} user_id={user_id} />
                                                                        : locationName == prefix + '/cases' || locationName == prefix + '/cases-' + caseStatus || locationName == prefix + '/cases-dsa/' + dsa_id || locationName == prefix + '/cases-lender/' + lender_id ?
                                                                            <Profile api={api} toast={toast} prefix={prefix} />
                                                                            : locationName == prefix + '/add-profile' || locationName == prefix + '/edit-profile/' + profile_id ?
                                                                                <AddEditProfile api={api} toast={toast} prefix={prefix} profile_id={profile_id} />
                                                                                : locationName == prefix + '/user' ?
                                                                                    <Users api={api} toast={toast} prefix={prefix} />
                                                                                    : locationName == prefix + '/add-user' || locationName == prefix + '/edit-user/' + user_id ?
                                                                                        <AddEditUser api={api} toast={toast} prefix={prefix} user_id={user_id} />
                                                                                        : locationName == prefix + '/query-builder' ?
                                                                                            <QueryBuilder api={api} toast={toast} prefix={prefix} />
                                                                                            : locationName == prefix + '/login-log' ?
                                                                                                <LoginLog api={api} toast={toast} prefix={prefix} />
                                                                                                : locationName == prefix + '/notification' ?
                                                                                                    <Notification api={api} toast={toast} prefix={prefix} />
                                                                                                    : locationName == prefix + '/state' ?
                                                                                                        <State api={api} toast={toast} prefix={prefix} />
                                                                                                        : locationName == prefix + '/city' ?
                                                                                                            <City api={api} toast={toast} prefix={prefix} />
                                                                                                            : locationName == prefix + '/pincode' ?
                                                                                                                <Pincode api={api} toast={toast} prefix={prefix} />
                                                                                                                : locationName == prefix + '/status' ?
                                                                                                                    <Status api={api} toast={toast} prefix={prefix} />
                                                                                                                    : locationName == prefix + '/editCity' + id ?
                                                                                                                        <EditCity api={api} toast={toast} prefix={prefix} id={id} />
                                                                                                                        : locationName == prefix + '/adminprofile' ?
                                                                                                                            <AdminProfile api={api} toast={toast} prefix={prefix} />
                                                                                                                            : locationName == prefix + '/changepassword' ?
                                                                                                                                <ChangePassword api={api} toast={toast} prefix={prefix} />
                                                                                                                                : locationName == prefix + '/settlement' ?
                                                                                                                                    <Settlement api={api} toast={toast} prefix={prefix} />
                                                                                                                                    : locationName == prefix + '/pendingsettlement' ?
                                                                                                                                        <PendingSettlement api={api} toast={toast} prefix={prefix} />
                                                                                                                                        : locationName == prefix + '/addSettle/' + id ?
                                                                                                                                            <AddSettlement api={api} toast={toast} prefix={prefix} user_id={user_id} />
                                                                                                                                            : locationName == prefix + '/directusers' ?
                                                                                                                                                <Directusers api={api} toast={toast} prefix={prefix} />
                                                                                                                                                : locationName == prefix + '/borrower' ?
                                                                                                                                                    <Borrower api={api} toast={toast} prefix={prefix} />
                                                                                                                                                    : locationName == prefix + '/employeedetails/' + id || locationName == prefix + '/applicant_details/' + id ?
                                                                                                                                                        <EmployeeDetails api={api} toast={toast} prefix={prefix} user_id={user_id} />
                                                                                                                                                        // : locationName == prefix + '/planlist' ?
                                                                                                                                                        //     <Planlist api={api} toast={toast}  prefix={prefix} />
                                                                                                                                                        : locationName == prefix + '/planlist/country' ?
                                                                                                                                                            <Country api={api} toast={toast} prefix={prefix} />
                                                                                                                                                            : locationName == prefix + '/planlist/lendercommission' ?
                                                                                                                                                                <LenderCommission api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                : locationName == prefix + '/planlist/merchantcommission' ?
                                                                                                                                                                    <MerchantCommission api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                    : locationName == prefix + '/editEmiplan/' + id ?
                                                                                                                                                                        <EditEmiplan api={api} toast={toast} prefix={prefix} id={id} />
                                                                                                                                                                        : locationName == prefix + '/lenderinterest' ?
                                                                                                                                                                            <LenderInterest api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                            : locationName == prefix + '/talk_to_us' ?
                                                                                                                                                                                <Talktous api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                : locationName == prefix + '/ewaplateformcharges' ?
                                                                                                                                                                                    <EWAplateformcharges api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                    : locationName == prefix + '/borrower_onboarding' ?
                                                                                                                                                                                        <Firststep api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                        : locationName == prefix + '/merchantdues' ?
                                                                                                                                                                                            <Merchantdues api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                            : locationName == prefix + '/merchantpaid' ?
                                                                                                                                                                                                <Merchantpaid api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                : locationName == prefix + '/merchantsettlement/' + id ?
                                                                                                                                                                                                    <Merchantsettlement api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                    : locationName == prefix + '/viewdetailbyid/' + id ?
                                                                                                                                                                                                        <ViewdetailbyID api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                        : locationName == (prefix + '/addemployer') /* || (prefix + '/editemployer/' + id) */ ?
                                                                                                                                                                                                            <AddEmployer api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                            : locationName == prefix + '/addlender' ?
                                                                                                                                                                                                                <AddLender api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                : locationName == prefix + '/addmerchant' ?
                                                                                                                                                                                                                    <Addmerchant api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                    : locationName == prefix + '/editemployer/' + id ?
                                                                                                                                                                                                                        <EditEmployer api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                        : locationName == prefix + '/editmerchant/' + id ?
                                                                                                                                                                                                                            <EditMerchant api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                            : locationName == prefix + '/bnpl_settlement' ?
                                                                                                                                                                                                                                <BNPLsettlement api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                : locationName == prefix + '/bnpl_transaction' ?
                                                                                                                                                                                                                                    <BNPLtransaction api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                    : locationName == prefix + '/settlementbyid/' + id ?
                                                                                                                                                                                                                                        <SettlementById api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                        : locationName == prefix + '/transactionbyid/' + id ?
                                                                                                                                                                                                                                            <TransactionById api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                            : locationName == prefix + '/employeelogs' ?
                                                                                                                                                                                                                                                <EmployeeLogs api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                : locationName == prefix + '/userloginlog' ?
                                                                                                                                                                                                                                                    <UserLoginlog api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                    : locationName == prefix + '/reconciliation' ?
                                                                                                                                                                                                                                                        <Viewreconciliation api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                        : locationName == prefix + '/ewa_earning' ?
                                                                                                                                                                                                                                                            <EwaEarning api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                            : locationName == prefix + '/bnpl_earning' ?
                                                                                                                                                                                                                                                                <BnplEarning api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                : locationName == prefix + '/ewa_earning_detail/' + id ?
                                                                                                                                                                                                                                                                    <EwaEarningdetail api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                    : locationName == prefix + '/employee_transaction' ?
                                                                                                                                                                                                                                                                        <Employeealltxn api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                        : locationName == prefix + '/manage_promotion' ?
                                                                                                                                                                                                                                                                            <Managepromotion api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                            : locationName == prefix + '/wallet_view_pending' ?
                                                                                                                                                                                                                                                                                <ViewPending api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                : locationName == prefix + '/wallet_txn_settlement/' + id ?
                                                                                                                                                                                                                                                                                    <TxnSettlement api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                    : locationName == prefix + '/wallet_view_paid' ?
                                                                                                                                                                                                                                                                                        <ViewPaid api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                        : locationName == prefix + '/wallet_paidbyid/' + id ?
                                                                                                                                                                                                                                                                                            <ViewPaidbyId api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                            : locationName == prefix + '/attendance_error_logs' ?
                                                                                                                                                                                                                                                                                                <AttendanceErrorlogs api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                : locationName == prefix + '/ewa_agree' ?
                                                                                                                                                                                                                                                                                                    <EwaAgree api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                    : locationName == prefix + '/ewa_advance_application' ?
                                                                                                                                                                                                                                                                                                        <EwaAdvanceapplication api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                        // : locationName == prefix + '/applicant_details/' + id ?
                                                                                                                                                                                                                                                                                                        //     <ApplicantDetails api={api} toast={toast}  prefix={prefix} />
                                                                                                                                                                                                                                                                                                        : locationName == prefix + '/sub-employer_detail/' + id ?
                                                                                                                                                                                                                                                                                                            <Subemployerdetail api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                            : locationName == prefix + '/sub-lender_detail/' + id ?
                                                                                                                                                                                                                                                                                                                <Sublenderdetail api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                : locationName == prefix + '/store/' + id ?
                                                                                                                                                                                                                                                                                                                    <Store api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                    : locationName == prefix + '/counter/' + id ?
                                                                                                                                                                                                                                                                                                                        <Counter api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                        : locationName == prefix + '/employer/trash' ?
                                                                                                                                                                                                                                                                                                                            <Trash api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                            : locationName == prefix + '/lender/trash' ?
                                                                                                                                                                                                                                                                                                                                <Lendertrash api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                : locationName == prefix + '/merchant/trash' ?
                                                                                                                                                                                                                                                                                                                                    <Merchanttrash api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                    : locationName == prefix + '/cashback' ?
                                                                                                                                                                                                                                                                                                                                        <Cashback api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                        : locationName == prefix + '/cashback_alldetail/' + id ?
                                                                                                                                                                                                                                                                                                                                            <Cashbackbyid api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                            : locationName == prefix + '/merchantdetail/' + id ?
                                                                                                                                                                                                                                                                                                                                                <Merchantdetail api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                : locationName == prefix + '/banktransfer' ?
                                                                                                                                                                                                                                                                                                                                                    <Banktransfer api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                    : locationName == prefix + '/lenderdetail/' + id ?
                                                                                                                                                                                                                                                                                                                                                        <LenderDetail api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                        : locationName == prefix + '/transfer_request' ?
                                                                                                                                                                                                                                                                                                                                                            <TransferRequest api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                            : locationName == prefix + '/planlist/balance_transfer' ?
                                                                                                                                                                                                                                                                                                                                                                <Balancetransfer api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                : locationName == prefix + '/view_investment' ?
                                                                                                                                                                                                                                                                                                                                                                    <ViewInvestment api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                    : locationName == prefix + '/planlist/investor_setting' ?
                                                                                                                                                                                                                                                                                                                                                                        <InvestorSetting api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                        : locationName == prefix + '/viewplan' ?
                                                                                                                                                                                                                                                                                                                                                                            <Viewplan api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                            : locationName == prefix + '/viewplan/view_plan_detail/' + id ?
                                                                                                                                                                                                                                                                                                                                                                                <ViewplanDetail api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                : locationName == prefix + '/request' ?
                                                                                                                                                                                                                                                                                                                                                                                    <Request api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                    : locationName == prefix + '/request/request_by_id/' + id ?
                                                                                                                                                                                                                                                                                                                                                                                        <Requestbyid api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                        : locationName == prefix + '/borrower/requestlogs_by_id/' + id ?
                                                                                                                                                                                                                                                                                                                                                                                            <Requestlogsbyid api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                            : locationName == prefix + '/monthly_ewa_deduction' ?
                                                                                                                                                                                                                                                                                                                                                                                                <MonthlyEWAdeduction api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                                : locationName == prefix + '/deduction_error_logs' ?
                                                                                                                                                                                                                                                                                                                                                                                                    <DeductionErrorlogs api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                                    : locationName == prefix + '/stripe_plan' ?
                                                                                                                                                                                                                                                                                                                                                                                                        <StripePlan api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                                        : locationName == prefix + '/plan_history/' + id ?
                                                                                                                                                                                                                                                                                                                                                                                                            <PlanHistory api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                                            : locationName == prefix + '/pf' ?
                                                                                                                                                                                                                                                                                                                                                                                                                <Pf api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                                                : locationName == prefix + '/taxslab' ?
                                                                                                                                                                                                                                                                                                                                                                                                                    <TaxSlab api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                                                    : locationName == prefix + '/taxslab_by_id/' + id ?
                                                                                                                                                                                                                                                                                                                                                                                                                        <TaxSlabByID api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                                                        : locationName == prefix + '/manage_sms' ?
                                                                                                                                                                                                                                                                                                                                                                                                                            <ManageSMS api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                                                            : locationName == prefix + '/manage_pin/' + id ?
                                                                                                                                                                                                                                                                                                                                                                                                                                <ManagePin api={api} toast={toast} prefix={prefix} />
                                                                                                                                                                                                                                                                                                                                                                                                                                : ''}
                        </div>
                    </div>


                    <Footer />
                </div>
            </div>
        </>

    )
}

export default Index