import { Image } from 'antd'
import React from 'react'

const Documents = ({ bank, employ, income, loan, pay, photo, tin, signature }) => {
    // console.log(bank, employ, income, loan, pay, photo, tin, signature)
    return (
        <div style={{ marginTop: "30px" }}>
            <h6>Documents</h6>
            <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                <div className="media-list p-0">
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col">
                            <div className="card  " style={{ border: "1px solid silver" }}>
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Tin Number</h3>
                                </div>
                                {tin.map((Tin, index) => {
                                    return (
                                        <>
                                            {(Tin?.file_type === '.pdf' || Tin?.file_type === '.PDF' || Tin?.file_type === '.docx') ? (
                                                <a href={Tin.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Tin ? Tin.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
            /* data-bs-target="#applicaiton-report1" data-bs-toggle="modal"
            onClick={() => setpaymentImage(Tin.doc_file)} */ />
                                            )}
                                            {/* <div className="modal fade effect-fall show" id="applicaiton-report1" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header"> <h6 className="modal-title">Tin</h6>
                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                <div className="modal-body application-modal" >
                    <img src={paymentImage} alt="..."
                        style={{ height: "80%", width: "80%" }}></img>
                </div>
            </div>
        </div>
    </div> */}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col">
                            <div className="card " style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Signature</h3>
                                </div>
                                {signature.map((Signature, index) => {
                                    return (
                                        <>
                                            {Signature?.file_type === '.pdf' || Signature?.file_type === '.PDF' || Signature?.file_type === '.docx' ? (
                                                <a href={Signature.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Signature ? Signature.doc_file : ''}
                                                    // onClick={() => setpaymentImage(Signature.doc_file)}
                                                    //     data-bs-target="#applicaiton-report2" data-bs-toggle="modal"
                                                    style={{ width: '70px', height: '100px', border: "1px solid silver" }}
                                                    className="card-img-top m-1" alt="..." />
                                            )}
                                            {/* <div className="modal fade effect-fall show" id="applicaiton-report2" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header"> <h6 className="modal-title">Signature</h6>
                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                <div className="modal-body application-modal" >
                    <img src={paymentImage} alt="..."
                        style={{ height: "80%", width: "80%" }}></img>
                </div>
            </div>
        </div>
    </div> */}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col">
                            <div className="card  " style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Photo ID</h3>
                                </div>
                                {photo.map((Photo, index) => {
                                    return (
                                        <>
                                            {(Photo?.file_type === '.pdf' || Photo?.file_type === '.PDF' || Photo?.file_type === '.docx') ? (
                                                <a href={Photo.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Photo ? Photo.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
            /* data-bs-target="#applicaiton-report3" data-bs-toggle="modal"
            onClick={() => setpaymentImage(Photo.doc_file)} */ />
                                            )}
                                            {/* <div className="modal fade effect-fall show" id="applicaiton-report3" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header"> <h6 className="modal-title">Photo ID</h6>
                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                <div className="modal-body application-modal" >
                    <img src={paymentImage} alt="..."
                        style={{ height: "80%", width: "80%" }}></img>
                </div>
            </div>
        </div>
    </div> */}
                                        </>
                                    )
                                })}

                            </div>
                        </div>
                        <div className="col">
                            <div className="card" style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Pay Slip</h3>
                                </div>
                                {pay.map((Pay, index) => {
                                    return (
                                        <>
                                            {(Pay?.file_type === '.pdf' || Pay?.file_type === '.PDF' || Pay?.file_type === '.docx') ? (
                                                <a href={Pay.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Pay ? Pay.doc_file : ''}
                                                    // onClick={() => setpaymentImage(Pay.doc_file)}
                                                    //     data-bs-target="#applicaiton-report4" data-bs-toggle="modal"
                                                    style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                            )}
                                            {/* <div className="modal fade effect-fall show" id="applicaiton-report4" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header"> <h6 className="modal-title">Pay Slip</h6>
                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                <div className="modal-body application-modal" >
                    <img src={paymentImage} alt="..."
                        style={{ height: "80%", width: "80%" }}></img>
                </div>
            </div>
        </div>
    </div> */}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col">
                            <div className="card  " style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Income Statement</h3>
                                </div>
                                {income.map((Income, index) => {
                                    return (
                                        <>
                                            {(Income?.file_type === '.pdf' || Income?.file_type === '.PDF' || Income?.file_type === '.docx') ? (
                                                <a href={Income.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Income ? Income.doc_file : ''}
                                                    style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                />)}
                                            {/*  data-bs-target="#applicaiton-report5" data-bs-toggle="modal"
            onClick={() => setpaymentImage(Income.doc_file)}   */}

                                            {/* <div className="modal fade effect-fall show" id="applicaiton-report5" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header"> <h6 className="modal-title">Income Statement</h6>
                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                <div className="modal-body application-modal" >
                    <img src={paymentImage} alt="..."
                        style={{ height: "80%", width: "80%" }}></img>
                </div>
            </div>
        </div>
    </div> */}
                                        </>
                                    )
                                })}

                            </div>
                        </div>
                        <div className="col">
                            <div className="card " style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Loan Statement</h3>
                                </div>
                                {loan.map((Loan, index) => {
                                    return (
                                        <>
                                            {(Loan?.file_type === '.pdf' || Loan?.file_type === '.PDF' || Loan?.file_type === '.docx') ? (
                                                <a href={Loan.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Loan ? Loan.doc_file : ''}
                                                    // onClick={() => setpaymentImage(Loan.doc_file)}
                                                    //     data-bs-target="#applicaiton-report6" data-bs-toggle="modal"
                                                    style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                            )}

                                            {/* <div className="modal fade effect-fall show" id="applicaiton-report6" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header"> <h6 className="modal-title">Loan Statement</h6>
                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                <div className="modal-body application-modal" >
                    <img src={paymentImage} alt="..."
                        style={{ height: "80%", width: "80%" }}></img>
                </div>
            </div>
        </div>
    </div> */}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col">
                            <div className="card  " style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Bank Statement</h3>
                                </div>
                                {bank.map((Bank, index) => {
                                    return (
                                        <>
                                            {(Bank?.file_type === '.pdf' || Bank?.file_type === '.PDF' || Bank?.file_type === '.docx') ? (
                                                <a href={Bank.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Bank ? Bank.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
            /* data-bs-target="#applicaiton-report7" data-bs-toggle="modal"
            onClick={() => setpaymentImage(Bank.doc_file)} */ />
                                            )}
                                            {/* <div className="modal fade effect-fall show" id="applicaiton-report7" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header"> <h6 className="modal-title">Bank Statement</h6>
                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                <div className="modal-body application-modal" >
                    <img src={paymentImage} alt="..."
                        style={{ height: "80%", width: "80%" }}></img>
                </div>
            </div>
        </div>
    </div> */}
                                        </>
                                    )
                                })}

                            </div>
                        </div>
                        <div className="col">
                            <div className="card" style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Employment Letter</h3>
                                </div>
                                {employ.map((Employ, index) => {
                                    return (
                                        <>
                                            {(Employ?.file_type === '.pdf' || Employ?.file_type === '.PDF' || Employ?.file_type === '.docx') ? (
                                                <a href={Employ.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Employ ? Employ.doc_file : ''}
                                                    // onClick={() => setpaymentImage(Employ.doc_file)}
                                                    //     data-bs-target="#applicaiton-report8" data-bs-toggle="modal"
                                                    style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                            )}

                                        </>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Documents