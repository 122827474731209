import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Breadcrumbs, Breadcrumbsecond } from '../../../components';
import { getApi, postApi } from '../../service/lenderService';
import Moment from 'react-moment';
import Applicantemi from '../../Loan/CasesDetails/Applicantemi';
import Applicationlogs from '../../Loan/CasesDetails/Applicationlogs';
import Riskanalysis from '../../Loan/CasesDetails/Riskanalysis';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import moment from 'moment';
import toast from 'react-hot-toast';
import { decrypt } from '../../../components/DecyptFunction/Decrypt';
import PersonalDetail from './UserDetails/personalDetail';
import Income from './UserDetails/Income';
import Asset from './UserDetails/Asset';
import Liabilities from './UserDetails/Liabilities';
import Monthly from './UserDetails/Monthly';
import Documents from './UserDetails/Documents';
import MlAnalyser from './UserDetails/mlAnalyser';
import Loader from '../../../components/Loader/loader';

const EmployeeDetail = () => {
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const navigate = useNavigate()
    const { id, type } = useParams();
    const location = useLocation();
    const tableRef = useRef(null);
    const [bank, setBank] = useState([])
    const [employ, setEmploy] = useState([])
    const [income, setIncome] = useState([])
    const [loan, setLoan] = useState([])
    const [pay, setPay] = useState([])
    const [photo, setPhoto] = useState([])
    const [tin, setTin] = useState([])
    const [signature, setSignature] = useState([])
    const [employeeDetail, setEmployeeDetail] = useState(Object);
    const [dataArray, setDataArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalAmount, settotalAmount] = useState();
    const [totalInterest, settotalInterest] = useState();
    const [Emivalidate, setEmivalidate] = useState(false)
    const [remainingDetail, setRemainingDetail] = useState()
    const [analyserModal, setAnalyserModal] = useState(false)
    const [accordionStatus, setAccordionStatus] = useState(false)

    useEffect(() => {
        if (location?.state?.status || location.pathname == `/lender/employee_detail/${id}/New` || location.pathname == `/lender/employee_detail/${id}/Existing`) {
            GetEmployeeData(id);

        } else {
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/manage_ewa_advance')
            toast.error('For View Applicant Detail, You Have To Verify PIN First')
        }
    }, [id]);


    const GetEmployeeData = async (id) => {
        try {
            if (location.pathname == `/lender/employee_detail/${id}/New` || location.pathname == `/lender/employee_detail/${id}/Existing`) {

                const response = await postApi(`/get-employee-num`, { mobile_number: id });
                if (response.status == true) {
                    setEmployeeDetail(response.data);
                    setBank(response.document.BANK_STATEMENTS)
                    setEmploy(response.document.EMPLOYMENT_LETTER)
                    setIncome(response.document.INCOME)
                    setLoan(response.document.LOAN_STATEMENTS)
                    setPay(response.document.PAYSLIPS)
                    setPhoto(response.document.PHOTO_ID)
                    setSignature(response.document.SIGNATURE)
                    setTin(response.document.TIN_LETTER)
                } else {
                    console.log("get employees data response", response);
                }
            } else {
                const response = await getApi(`/user-loan-application/${id}`)
                if (response.status === true) {
                    setEmployeeDetail(response.data.userData)
                    setBank(response?.data?.docsData.BANK_STATEMENTS)
                    setEmploy(response?.data?.docsData.EMPLOYMENT_LETTER)
                    setIncome(response?.data?.docsData.INCOME)
                    setLoan(response?.data?.docsData.LOAN_STATEMENTS)
                    setPay(response?.data?.docsData.PAYSLIPS)
                    setPhoto(response?.data?.docsData.PHOTO_ID)
                    setSignature(response?.data?.docsData.SIGNATURE)
                    setTin(response?.data?.docsData.TIN_LETTER)
                } else {
                    console.log(response)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }



    const GetEmployeeEMIs = async (id) => {
        try {
            if (location.pathname == `/lender/employee_detail/${id}/New` || location.pathname == `/lender/employee_detail/${id}/Existing`) {
                setEmivalidate(true)
                const response = await getApi(`/get-emi-byId/${id}`);
                if (response.status == true) {
                    setDataArray(response.data);
                    setTotalPagesCount(response.totleEmi)
                    settotalAmount(response.totalAmount)
                    settotalInterest(response.totalInterest)
                } else {
                    console.log("get employees data response", response);
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        GetEmployeeEMIs(id);
    }, []);

    var num3 = parseFloat(totalInterest).toFixed(2)
    const TotalOutgoing = (employeeDetail.rent + employeeDetail.electricity + employeeDetail.bill_for_phone + employeeDetail.bill_for_water
        + employeeDetail.house_keeping + employeeDetail.school_fees + employeeDetail.travelling + employeeDetail.entertainment)

    const Surplus = (employeeDetail.monthly_income - TotalOutgoing)
    const newMFLRepayment = (totalAmount)
    const SurplusAvailable = (Surplus - newMFLRepayment)

    function currencyFormat(num) {
        if (/-/g.test(num)) {
            return num?.toFixed(2).replace(/-/g, '-$')
        } else {
            return "$" + num?.toFixed(2)
        }
    }

    const netWeeklysurplus = parseFloat((SurplusAvailable * 12) / 52).toFixed(2);
    var netWeeklysurpluss
    if (/-/g.test(netWeeklysurplus)) {
        netWeeklysurpluss = netWeeklysurplus?.replace(/-/g, '-$');

    } else {
        netWeeklysurpluss = "$" + netWeeklysurplus?.trim()
    }

    const DebtIncomeRatio = parseFloat(newMFLRepayment / Surplus).toFixed(2)
    var new_str = DebtIncomeRatio.replace(/-/g, '-$');

    const [mlData, setMLdata] = useState('')
    const [behaviorData, setBehaviorData] = useState([])

    // const letsDecrypt = async (behaviorData) => {
    //     try {
    //         const updatedBehaviorData = {
    //             ...behaviorData,
    //             userMessages: await Promise.all(
    //                 behaviorData?.userMessages?.map(async (behavior) => {
    //                     const updatedContact = await Promise.all(
    //                         behavior?.contacts?.map(async (contactObj) => {
    //                             const updatedMessages = await Promise.all(
    //                                 contactObj?.messages?.map(async (messageObj) => {
    //                                     try {
    //                                         const originalMsg = await decrypt(messageObj?.message?.replace(/[\r\n]+/g, ''));
    //                                         const originalContact = await decrypt(messageObj?.contact_number?.replace(/[\r\n]+/g, ''));
    //                                         return { ...messageObj, message: originalMsg, contact_number: originalContact };
    //                                     } catch (error) {
    //                                         console.error(`Error decrypting message: ${error}`);
    //                                         return { ...messageObj, message: 'Error decrypting message' };
    //                                     }
    //                                 })
    //                             );
    //                             return {
    //                                 ...contactObj,
    //                                 contact: await decrypt(contactObj.contact?.replace(/[\r\n]+/g, '')),
    //                                 messages: updatedMessages
    //                             };
    //                         })
    //                     );
    //                     return { ...behavior, contacts: updatedContact };
    //                 })
    //             ),
    //             userCallLogs: await Promise.all(
    //                 behaviorData?.userCallLogs?.map(async (behavior) => {
    //                     const updatedContacts = await Promise.all(
    //                         behavior?.contacts?.map(async (contactObj) => {
    //                             try {
    //                                 const decryptedContact = await decrypt(contactObj?.contact?.replace(/[\r\n]+/g, ''));
    //                                 return { ...contactObj, contact: decryptedContact };
    //                             } catch (error) {
    //                                 console.error(`Error decrypting contact: ${error}`);
    //                                 return { ...contactObj, contact: 'Error decrypting contact' };
    //                             }
    //                         })
    //                     );
    //                     return { ...behavior, contacts: updatedContacts };
    //                 })
    //             )
    //         };

    //         setBehaviorData(updatedBehaviorData);
    //         console.log(updatedBehaviorData, "updatedbehavior")
    //         // setAccordionStatus(true)
    //     } catch (error) {
    //         console.error(`Error in letsDecrypt: ${error}`);
    //     }
    // };

    const GetMLanalysis = async () => {
        try {

            const response = await getApi(`/ml-analysis/${id}`);
            if (response.status == true) {
                // setDataArray(response.data);
                setMLdata(response.jsonFileName)
                setBehaviorData(response.data)
                // letsDecrypt(response.data)
                // letsDecrypt2(response.data)
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetMLanalysis();
    }, []);

    // const handlegetJSONdata = () => {
    //     const jsonData = JSON.stringify(behaviorData);
    //     const blob = new Blob([jsonData], { type: 'application/json' });
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'Raw Data.json';
    //     a.click();
    // }
    // const [highlightData, setHighlightedData] = useState([])

    const highlightKeywords = (message) => {
        const keywords = [
            "can’t pay", "unable to pay", "won’t be able to pay",
            "extension", "delay", "postpone payment",
            "partial payment", "installment", "part payment",
            "lost job", "unemployed", "reduced hours", "salary cut",
            "high expenses", "unexpected expenses", "medical bills",
            "need help", "financial assistance", "support",
            "bank", "account", "balance",
            "credit card", "card payment", "card debt",
            "loan payment", "loan balance", "loan due",
            "urgent", "immediate", "ASAP",
            "negotiate", "settle", "agreement"
        ];

        const regex = new RegExp(`(${keywords.join('|')})`, 'gi');
        return message.split(regex).map((part, index) => {
            if (keywords.some(keyword => keyword.toLowerCase() === part.toLowerCase())) {
                return <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>;
            }
            return part;
        });
    };



    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header my-3 py-3 mb-0">
                    <div className="page-leftheader tabsbtm">
                        {/* <div className="modal-header w-100" > */}
                        {/* <h3 className="page-title mb-0 text-primary">Borrower <i className='fas fa-arrow-right'/> Details</h3> */}
                        {Emivalidate ? <Breadcrumbs order={{ first: { name: "Borrower" }, second: { name: type == "Existing" ? "Existing Borrower" : "New Borrower", path: type == "Existing" ? `${prefix}/employee` : `${prefix}/newborrower` }, third: { name: "Details" } }} />
                            :
                            <Breadcrumbsecond order={{ first: { name: "Manage EWA Advance", path: `${prefix}/manage_ewa_advance` }, second: { name: "Details" } }} />}
                    </div>
                    {/* <Link to={prefix + '/employee'}  >
                        <i className='fas fa-arrow-left' /> Back</Link>  */}
                    {/* </div> */}
                    <div style={{ width: "100%" }} className='tabFlexChange'>

                        <div className="col-md-12">
                            <Tabs
                                defaultActiveKey="personal"
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                            >
                                <Tab eventKey="personal" title="Personal" disabled={analyserModal} >
                                    <PersonalDetail employeeDetail={employeeDetail} />
                                </Tab>
                                <Tab eventKey="profile" title="Income" disabled={analyserModal}>
                                    <Income employeeDetail={employeeDetail} />
                                </Tab>
                                <Tab eventKey="assest" title="Assets" disabled={analyserModal} >
                                    <Asset employeeDetail={employeeDetail} />

                                </Tab>
                                <Tab eventKey="liabilities" title="Liabilities" disabled={analyserModal} >
                                    <Liabilities employeeDetail={employeeDetail} />

                                </Tab>
                                <Tab eventKey="monthly" title="Living Cost" disabled={analyserModal} >
                                    <Monthly employeeDetail={employeeDetail} />

                                </Tab>
                                <Tab eventKey="documents" title="Docs" disabled={analyserModal} >
                                    <Documents bank={bank} employ={employ} income={income} loan={loan} pay={pay} photo={photo} tin={tin} signature={signature} />
                                </Tab>
                                <Tab eventKey="emiDetais" title="Repayments" disabled={analyserModal} >
                                    {/* <EmiDetails /> */}
                                    {Emivalidate ?
                                        <div style={{ marginTop: "30px" }}>
                                            <div className="page-header mb-3">
                                                <div className="page-leftheader">
                                                    <h5>Repayment Details</h5>
                                                </div>
                                                <div className="page-rightheader">
                                                    <h5>{totalAmount !== "0" ? "Total Amount : $" + totalAmount : ""}
                                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <span>{totalInterest !== "0" ? "Total Interest :  $" + num3 : ""}</span></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card">
                                                        <div className="card-body p-0">
                                                            <div className="table-responsive" >
                                                                <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                                        <tr>
                                                                            <th className="font-weight-bold">Sr No.</th>
                                                                            <th className="font-weight-bold">Repayment Date</th>
                                                                            <th className="font-weight-bold">Borrower</th>
                                                                            <th className="font-weight-bold">Amount</th>
                                                                            <th className="font-weight-bold">Late Charge</th>
                                                                            <th className="font-weight-bold">Interest of Days</th>
                                                                            <th className="font-weight-bold">Interest</th>
                                                                            <th className="font-weight-bold">Status</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dataArray && dataArray.length > 0 && dataArray.map((option, index) => {
                                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                            const { first_name, last_name, amount, status, late_charges, mobile_number, interest_of_days, due_date } = option;
                                                                            // var Capitalize = (option) => {
                                                                            //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                                            // }
                                                                            var num2 = parseFloat(option.interest).toFixed(2)
                                                                            return (
                                                                                <tr key={serial_num} >
                                                                                    <td><span className="font-weight-normal1">{index + 1}</span></td>
                                                                                    <td><span className="font-weight-normal1"><Moment format="YYYY-MM-DD ">{due_date}</Moment></span></td>
                                                                                    <td><span className="font-weight-normal1">
                                                                                        {first_name ? first_name : ""}  {last_name ? last_name : ""}<br />{mobile_number}
                                                                                    </span></td>
                                                                                    <td><span className="font-weight-normal1">{option.amount ? "$" + amount : ""}</span></td>
                                                                                    {/* <td><span className="font-weight-normal1">{available_limit}</span></td>   */}
                                                                                    <td><span className="font-weight-normal1">{option.late_charges ? "$" + late_charges : ""}</span></td>
                                                                                    {/* <td><span className="font-weight-normal1">{total_paid_amount}</span></td> */}
                                                                                    <td><span className="font-weight-normal1">{option.interest_of_days ? interest_of_days + " Days " : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option.interest ? "$" + num2 : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{status ? status : ''}</span></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </tbody>
                                                                    <tbody>
                                                                        {dataArray.length == 0 &&
                                                                            <tr>
                                                                                <td colSpan={10} className='text-center'>No Repayment's Data Available</td>
                                                                            </tr>}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''} */}

                                            </div>
                                        </div> : <Applicantemi />}
                                </Tab>
                                {!Emivalidate && <Tab eventKey="purpose" title="Purpose" disabled={analyserModal} >
                                    <div style={{ marginTop: "30px" }}>
                                        <h3>Purpose of EWA Advance</h3>
                                        <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                                            <div className="media-list p-0">
                                                <div className="media py-4 mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-id-card"></i> </div>
                                                            <div className="w-70"> <label>Purpose of EWA Advance : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.purpose_of_ewa_advance ? employeeDetail?.purpose_of_ewa_advance : '--'}</span> </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-money fa-fw"></i> </div>
                                                            <div className="w-70"> <label>Monthly Business Income :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.monthly_business_income ? "$" + employeeDetail?.monthly_business_income : '--'}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                </Tab>}
                                {!Emivalidate && <Tab eventKey="Applicationlogs" title="Logs" disabled={analyserModal} >
                                    <Applicationlogs />
                                </Tab>}
                                <Tab eventKey="RiskAnalysis " title="Risk Analysis " disabled={analyserModal} >
                                    {/* <RiskAnalysis /> */}
                                    {Emivalidate ?
                                        <div style={{ marginTop: "30px" }}>
                                            <h5>Risk Analysis</h5>
                                            <div className="main-contact-info-body" style={{ marginTop: "30px" }}>
                                                <div className="media-list p-0">
                                                    <div className="media py-4 mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                                                <div className="w-70"> <label>Total Outgoing : </label> <span className="font-weight-normal1 fs-14">{currencyFormat(TotalOutgoing)}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-hand-holding-usd"></i> </div>
                                                                <div className="w-70"> <label>Surplus :</label> <span className="font-weight-normal1 fs-14">{currencyFormat(Surplus)}</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media py-4 border-top mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fab fa-amazon-pay"></i> </div>
                                                                <div className="w-70"> <label>New MFL Repayment :</label> <span className="font-weight-normal1 fs-14">{"$" + newMFLRepayment}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1 "> <i className="fas fa-sort-amount-up" ></i> </div>
                                                                <div className="w-70"> <label>Surplus Available :</label> <span className="font-weight-normal1 fs-14">{currencyFormat(SurplusAvailable)}</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media py-4 border-top mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-sort-amount-up"></i> </div>
                                                                <div className="w-70"> <label>Net Weekly Surplus :</label> <span className="font-weight-normal1 fs-14">{netWeeklysurpluss}</span> </div>

                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-chart-pie"></i> </div>
                                                                <div className="w-70"> <label>DTI (Debt to Income Ratio) :</label> <span className="font-weight-normal1 fs-14">
                                                                    {currencyFormat(Number(new_str))}</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <Riskanalysis />}
                                </Tab>
                                {!Emivalidate &&
                                    <Tab eventKey="ml Analysis" title="ML Analysis"  >
                                        <MlAnalyser setAnalyserModal={setAnalyserModal} analyserModal={analyserModal} mlData={mlData} setRemainingDetail={setRemainingDetail} remainingDetail={remainingDetail} GetMLanalysis={GetMLanalysis} />

                                    </Tab>}
                                {!Emivalidate &&
                                    <Tab eventKey="Behavior" title="Behavior" disabled={analyserModal} >
                                        <div style={{ marginTop: "30px" }} className='d-flex justify-content-between mb-3'>

                                            <h5>Behavior Analysis</h5>
                                            {/* <button className="btn btn-primary" onClick={() => handlegetJSONdata()}><i className='fas fa-download' /> Download Data</button> */}
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                SMS Analysis
                                            </button>
                                            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="staticBackdropLabel">SMS Analysis </h5>
                                                            <button type="button" className="btn btn-dark" data-bs-dismiss="modal"><i className='fas fa-close' /></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                                    <tr>
                                                                        <th className="font-weight-bold">Id</th>
                                                                        <th className="font-weight-bold">Contact</th>
                                                                        <th className="font-weight-bold">Date</th>
                                                                        <th className="font-weight-bold">Time</th>
                                                                        <th className="font-weight-bold">Message</th>
                                                                        <th className="font-weight-bold">Activity Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* {!accordionStatus ? (
                                                                        <tr>
                                                                            <td colSpan="2" className="text-center"></td>
                                                                            <td className="text-center"><Loader /></td>
                                                                            <td colSpan="3" className="text-center"></td>
                                                                        </tr>
                                                                    ) : ( */}
                                                                    <>
                                                                        {behaviorData?.userMessages?.length > 0 ? (
                                                                            behaviorData.userMessages.map((dateObj, dateIndex) => {
                                                                                return dateObj.contacts.map((contact, contactIndex) => {
                                                                                    return contact.messages.filter(message => {
                                                                                        return highlightKeywords(message?.message).some(part => {
                                                                                            return typeof part === 'object';
                                                                                        });
                                                                                    }).map((message, messageIndex) => {
                                                                                        const serial_num = ((10 * (pagesCount - 1)) + messageIndex + 1);
                                                                                        return (
                                                                                            <tr key={serial_num}>
                                                                                                <td><span className="font-weight-normal1">{message.id}</span></td>
                                                                                                <td><span className="font-weight-normal1">{contact.contact}</span></td>
                                                                                                <td><span className="font-weight-normal1">{message.date}</span></td>
                                                                                                <td><span className="font-weight-normal1">{message.time}</span></td>
                                                                                                <td><span className="font-weight-normal1"
                                                                                                    style={{
                                                                                                        whiteSpace: 'normal',
                                                                                                        wordBreak: 'break-all',
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        maxWidth: '250px'
                                                                                                    }}
                                                                                                >
                                                                                                    {highlightKeywords(message?.message)}
                                                                                                </span>
                                                                                                </td>
                                                                                                <td><span className="font-weight-normal1">{TimeZone(message?.created_at).timeZone}</span></td>
                                                                                            </tr>
                                                                                        );
                                                                                    });
                                                                                });
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="6" className="text-center">No data available</td>
                                                                            </tr>
                                                                        )}
                                                                    </>
                                                                    {/* )} */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion accordion-flush" id="accordionFlushExample" style={{ zIndex: '1', position: 'relative' }}>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        App Launch Detail
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '200px', marginBottom: '10rem' }}>
                                                            <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                                    <tr>
                                                                        {/* <th className="font-weight-bold">Id </th><th className="font-weight-bold">Customer ID  </th> */}
                                                                        <th className="font-weight-bold">Count  </th>
                                                                        <th className="font-weight-bold"> Date </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody >
                                                                    {behaviorData?.appLaunchCount?.length > 0 && behaviorData?.appLaunchCount.map((option, index) => {
                                                                        let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                        const { date_time, launchCount } = option;
                                                                        return (
                                                                            <tr key={serial_num} >
                                                                                {/* <td><span className="font-weight-normal1">{option?.id ? id : ""}</span></td> */}
                                                                                <td><span className="font-weight-normal1">{option?.launchCount ? launchCount : ""}</span></td>
                                                                                <td><span className="font-weight-normal1">{option?.date_time ? date_time : ""}</span></td>
                                                                                {/* <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td> */}
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    )}
                                                                </tbody>
                                                                <tbody>
                                                                    {behaviorData?.appLaunchCount?.length == 0 &&
                                                                        <tr>
                                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                            EWA Advance Screen Interaction Data
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '200px' }}>
                                                                <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                                        <tr>
                                                                            <th className="font-weight-bold">Id </th>
                                                                            {/* <th className="font-weight-bold">Customer ID  </th> */}
                                                                            <th className="font-weight-bold">Screen Name  </th>
                                                                            <th className="font-weight-bold">Start Time  </th>
                                                                            <th className="font-weight-bold">End Time  </th>
                                                                            <th className="font-weight-bold">Duration  </th>
                                                                            <th className="font-weight-bold">Activity Date </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {behaviorData?.loanScreenInteractionData?.length > 0 && behaviorData?.loanScreenInteractionData.map((option, index) => {
                                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                            const { id, customer_id, screen_name, start_time, end_time, total_duration } = option;
                                                                            return (
                                                                                <tr key={serial_num} >
                                                                                    <td><span className="font-weight-normal1">{option?.id ? id : ""}</span></td>
                                                                                    {/* <td><span className="font-weight-normal1">{option?.customer_id ? customer_id : ""}</span></td> */}
                                                                                    <td><span className="font-weight-normal1">{option?.screen_name ? screen_name : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.start_time ? moment(option.start_time / 1000, 'X').format('HH:mm:ss A') : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.end_time ? moment(option.end_time / 1000, 'X').format('HH:mm:ss A') : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.total_duration ? total_duration : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </tbody>
                                                                    <tbody>
                                                                        {behaviorData?.loanScreenInteractionData?.length == 0 &&
                                                                            <tr>
                                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                                            </tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingThree">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                            Message Activity
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '200px' }}>
                                                                <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                                        <tr>
                                                                            <th className="font-weight-bold">Id </th>
                                                                            <th className="font-weight-bold">Contact</th>
                                                                            <th className="font-weight-bold">Date</th>
                                                                            <th className="font-weight-bold">Time</th>
                                                                            <th className="font-weight-bold">Message</th>
                                                                            <th className="font-weight-bold">Activity Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {/* {!accordionStatus ? (
                                                                            <tr>
                                                                                <td colSpan="2" className="text-center"></td>
                                                                                <td className="text-center"><Loader /></td>
                                                                                <td colSpan="3" className="text-center"></td>
                                                                            </tr>
                                                                        ) : (
                                                                            <> */}
                                                                        {behaviorData?.userMessages?.length > 0 ? (
                                                                            behaviorData.userMessages.map((dateObj, dateIndex) => {
                                                                                return dateObj.contacts.map((contact, contactIndex) => {
                                                                                    return contact.messages.map((message, messageIndex) => {
                                                                                        const serial_num = ((10 * (pagesCount - 1)) + messageIndex + 1);
                                                                                        return (
                                                                                            <tr key={serial_num}>
                                                                                                <td><span className="font-weight-normal1">{message.id}</span></td>
                                                                                                <td><span className="font-weight-normal1">{contact.contact}</span></td>
                                                                                                <td><span className="font-weight-normal1">{message.date}</span></td>
                                                                                                <td><span className="font-weight-normal1">{message.time}</span></td>
                                                                                                <td><span className="font-weight-normal1"
                                                                                                    style={{
                                                                                                        whiteSpace: 'normal',
                                                                                                        wordBreak: 'break-all',
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        maxWidth: '250px'
                                                                                                    }}
                                                                                                > {highlightKeywords(message?.message)}
                                                                                                    {/* {message?.message}*/}

                                                                                                </span>
                                                                                                </td>
                                                                                                <td><span className="font-weight-normal1">{TimeZone(message?.created_at).timeZone}</span></td>



                                                                                            </tr>
                                                                                        );
                                                                                    });
                                                                                });
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="6" className="text-center">No data available</td>
                                                                            </tr>
                                                                        )}
                                                                        {/* </> */}
                                                                        {/* )} */}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingFour">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                            User Call Logs
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '200px' }}>
                                                                <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                                        <tr>
                                                                            <th className="font-weight-bold">Id </th>
                                                                            <th className="font-weight-bold">User Name  </th>
                                                                            <th className="font-weight-bold">Contact No</th>
                                                                            <th className="font-weight-bold">Type  </th>
                                                                            <th className="font-weight-bold">Duration  </th>
                                                                            <th className="font-weight-bold">Start Time  </th>
                                                                            <th className="font-weight-bold">End Time  </th>
                                                                            <th className="font-weight-bold">Call Date  </th>
                                                                            <th className="font-weight-bold">Created Date </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {behaviorData?.userCallLogs?.length > 0 ? (
                                                                            behaviorData.userCallLogs.map((dateObj, dateIndex) => {
                                                                                return dateObj.contacts.map((contact, contactIndex) => {
                                                                                    return contact.callLogs.map((logs, messageIndex) => {
                                                                                        const serial_num = ((10 * (pagesCount - 1)) + messageIndex + 1);
                                                                                        return (
                                                                                            <tr key={serial_num}>
                                                                                                <td><span className="font-weight-normal1">{logs.id}</span></td>
                                                                                                <td><span className="font-weight-normal1">{logs.user_name}</span></td>
                                                                                                <td><span className="font-weight-normal1">{contact.contact}</span></td>
                                                                                                <td><span className="font-weight-normal1">{logs.call_type}</span></td>
                                                                                                <td><span className="font-weight-normal1">{logs.call_duration}</span></td>
                                                                                                <td><span className="font-weight-normal1">{logs.start_time}</span></td>
                                                                                                <td><span className="font-weight-normal1">{logs.end_time}</span></td>
                                                                                                <td><span className="font-weight-normal1">{logs.call_date}</span></td>
                                                                                                <td><span className="font-weight-normal1">{TimeZone(logs?.created_at).timeZone}</span></td>

                                                                                            </tr>
                                                                                        );
                                                                                    });
                                                                                });
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="7" className="text-center">No data available</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                    {/* <tbody>
                                                                        {behaviorData?.loanScreenInteractionData?.length == 0 &&
                                                                            <tr>
                                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                                            </tr>
                                                                        }
                                                                    </tbody> */}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingFive">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                            Borrower Location Data
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '200px' }}>
                                                                <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                                        <tr>
                                                                            <th className="font-weight-bold">Id </th>
                                                                            {/* <th className="font-weight-bold">Customer ID  </th> */}
                                                                            <th className="font-weight-bold">latitude  </th>
                                                                            <th className="font-weight-bold">longitude  </th>
                                                                            <th className="font-weight-bold">address  </th>
                                                                            <th className="font-weight-bold">Activity Date </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {behaviorData?.borrowerLocationData?.length > 0 && behaviorData?.borrowerLocationData.map((option, index) => {
                                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                            const { id, latitude, longitude, address } = option;
                                                                            return (
                                                                                <tr key={serial_num} >
                                                                                    <td><span className="font-weight-normal1">{option?.id ? id : ""}</span></td>
                                                                                    {/* <td><span className="font-weight-normal1">{option?.customer_id ? customer_id : ""}</span></td> */}
                                                                                    <td><span className="font-weight-normal1">{option?.latitude ? latitude : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.longitude ? longitude : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1"
                                                                                        style={{
                                                                                            whiteSpace: 'normal',
                                                                                            wordBreak: 'break-all',
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            maxWidth: '280px'
                                                                                        }}>
                                                                                        {option?.address ? address : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </tbody>
                                                                    <tbody>
                                                                        {behaviorData?.borrowerLocationData?.length == 0 &&
                                                                            <tr>
                                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                                            </tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingSix">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                            User Device Detail
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '200px' }}>
                                                                <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                                        <tr>
                                                                            <th className="font-weight-bold">Id </th>
                                                                            {/* <th className="font-weight-bold">Customer ID  </th> */}
                                                                            <th className="font-weight-bold">Device Type  </th>
                                                                            <th className="font-weight-bold">Modal  </th>
                                                                            <th className="font-weight-bold">Manufacturing Year  </th>
                                                                            <th className="font-weight-bold">Purchase Year  </th>
                                                                            <th className="font-weight-bold">Device ID  </th>
                                                                            <th className="font-weight-bold">Version  </th>
                                                                            <th className="font-weight-bold">Network Type </th>
                                                                            <th className="font-weight-bold">Network Quality </th>
                                                                            <th className="font-weight-bold">Activity Date </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {behaviorData?.userDeviceDetail?.length > 0 && behaviorData?.userDeviceDetail.map((option, index) => {
                                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                            const { id, device_type, model, manufacturing_year, purchase_year, device_id, os_version, network_type, network_quality } = option;
                                                                            return (
                                                                                <tr key={serial_num} >
                                                                                    <td><span className="font-weight-normal1">{option?.id ? id : "--"}</span></td>
                                                                                    {/* <td><span className="font-weight-normal1">{option?.customer_id ? customer_id : "--"}</span></td> */}
                                                                                    <td><span className="font-weight-normal1">{option?.device_type ? device_type : "--"}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.model ? model : "--"}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.manufacturing_year ? manufacturing_year : "--"}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.purchase_year ? purchase_year : "--"}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.device_id ? device_id : "--"}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.os_version ? os_version : "--"}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.network_type ? network_type : "--"}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.network_quality ? network_quality : "--"}</span></td>
                                                                                    <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </tbody>
                                                                    <tbody>
                                                                        {behaviorData?.userDeviceDetail?.length == 0 &&
                                                                            <tr>
                                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                                            </tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeDetail