import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { UseAppContext } from '../../Context/AppContext';
import { GetPermission, getApi } from '../service/lenderService';


export const Header = (props) => {
   const navigate = useNavigate();
   const { header, ToggleHeader, logo } = UseAppContext();
   const [profileData, setprofileData] = useState({});
   const prefix = process.env.REACT_APP_LENDER_PRIFIX;
   const [settinngSubmenu, setSettingSubmenu] = useState(false)
   const [settinngSubmenuthirdroc, setSettingSubmenuthirdroc] = useState(false)
   const [settinngSubmenuEMI, setSettingSubmenuEMI] = useState(false)
   const [rolepermission, setRolepermission] = useState(false)
   const [submenuborrower, setsubmenuborrower] = useState(false)
   const [settingSubmenuloan, setSettingSubmenuloan] = useState(false)
   const [manageinvestment, setManageinvestment] = useState(false)
   const [lendername, setlendername] = useState();
   const [lendermobile, setlendermobile] = useState();
   const lender = JSON.parse(localStorage.getItem("lender"));
   const user_type = lender.user_type
   let { pathname } = useLocation()
   const getProfile = async (token) => {
      // const response = await GetProfile(token);
      const response = await getApi("/get-profile");
      if (response.status == true) {
         setprofileData(response.data);
         setlendername(response.data.company_name)
         setlendermobile(response.data.mobile_number)
      } else {
         console.log("get employees data response", response);
      }
   }

   const Borrower = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containerborrower");
      let ele = [...dropdown]
      // console.log(ele);
      //paytoEMI console.log(ele[0].style.display);

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setsubmenuborrower(true)
      } else {
         dropdown[0].style.display = "none"
         setsubmenuborrower(false)
      }
   }

   const paytoEMI = async () => {

      var dropdown = document.getElementsByClassName("dropdown-containerEMI");
      let ele = [...dropdown]
      // console.log(ele);
      //paytoEMI console.log(ele[0].style.display);

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenuEMI(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenuEMI(false)
      }
   }
   const RolesandPermissions = async () => {

      var dropdown = document.getElementsByClassName("dropdown-containerRole");
      let ele = [...dropdown]
      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setRolepermission(true)
      } else {
         dropdown[0].style.display = "none"
         setRolepermission(false)
      }
   }
   const showSubMenu = async () => {

      var dropdown = document.getElementsByClassName("dropdown-container");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenu(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenu(false)
      }
   }
   const showSubMenuloan = async () => {

      var dropdown = document.getElementsByClassName("dropdown-containerloan");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenuloan(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenuloan(false)
      }
   }
   const paytothirdroc = async () => {

      var dropdown = document.getElementsByClassName("dropdown-containerthird");
      let ele = [...dropdown]
      // console.log(ele);
      //paytoEMI console.log(ele[0].style.display);

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenuthirdroc(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenuthirdroc(false)
      }
   }

   const ManageInvestment = async () => {

      var dropdown = document.getElementsByClassName("dropdown-containerinvestment");
      let ele = [...dropdown]
      // console.log(ele);
      //paytoEMI console.log(ele[0].style.display);

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setManageinvestment(true)
      } else {
         dropdown[0].style.display = "none"
         setManageinvestment(false)
      }
   }

   useEffect(() => {
      getProfile(lender.lender_token)
   }, [logo])

   const logOut = () => {
      localStorage.removeItem("lender");
      navigate('/lender/login')
   }
   let role
   const [permission_data, setPermission_data] = useState([])
   const GetPermissions = async (token) => {
      try {
         const response = await GetPermission(token);
         // console.log(response)
         if (response.status) {
            role = JSON.parse(response.data.permissions);
            setPermission_data(role);

            return role
         }
      } catch (error) {
         console.error("🚀 ~ file: GetPermissions ~ error:", error)
      }
   }

   useEffect(() => {
      if (lender.user_type == "SUB-LENDERS") {
         GetPermissions(lender.lender_token)
      }
   }, [])

   const [newemployee_view, setNewemployee_view] = useState(false)
   const [existingemployee_view, setExistingemployee_view] = useState(false)
   const [merchant_pending_view, setmerchant_pending_view] = useState(false)
   const [merchant_paid_view, setmerchant_paid_view] = useState(false)
   const [thirdroc_pending_view, setthirdroc_pending_view] = useState(false)
   const [thirdroc_paid_view, setthirdroc_paid_view] = useState(false)
   const [repayment_view_pending, setrepayment_view_pending] = useState(false)
   const [repayment_view_paid, setrepayment_view_paid] = useState(false)
   const [roi_paid_view, setroi_paid_view] = useState(false)
   const [view_loan_application, setview_loan_application] = useState(false)
   const [view_loan_status, setview_loan_status] = useState(false)

   const handlePermissionAPI = async () => {
      if (lender.user_type == "SUB-LENDERS") {
         let option
         await GetPermissions(lender.lender_token).then((data) => {
            option = data;
         })

         setNewemployee_view(false)
         setExistingemployee_view(false)
         setmerchant_pending_view(false)
         setmerchant_paid_view(false)
         setthirdroc_pending_view(false)
         setthirdroc_paid_view(false)
         setrepayment_view_pending(false)
         setrepayment_view_paid(false)
         setroi_paid_view(false)
         setview_loan_application(false)
         setview_loan_status(false)
         if (permission_data) {
            if (option.length > 0) {
               option.forEach((option) => {

                  if (
                     option.key === "new_employee_view" ||
                     option.key === "new_employee_limit"
                  ) {
                     setNewemployee_view(true);
                  }
                  if (
                     option.key === "existing_employee_view" ||
                     option.key === "existing_employee_limit"
                  ) {
                     setExistingemployee_view(true);
                  }
                  if (option.key === "merchant_pending_view" ||
                     option.key === "merchant_pending_settlement_view" ||
                     option.key === "merchant_add_settlement") {
                     setmerchant_pending_view(true);
                  }
                  if (
                     option.key === "merchant_paid_settlement" ||
                     option.key === "merchant_update_paid_txn" ||
                     option.key === "merchant_paid_view"
                  ) {
                     setmerchant_paid_view(true);
                  }
                  if (
                     option.key === "thirdroc_pending_view" ||
                     option.key === "thirdroc_add_settlement"
                  ) {
                     setthirdroc_pending_view(true);
                  }
                  if (
                     option.key === "thirdroc_paid_view" ||
                     option.key === "thirdroc_update_paid_txn" ||
                     option.key === "thirdroc_paid_settlement"
                  ) {
                     setthirdroc_paid_view(true);
                  }
                  if (
                     option.key === "repayment_view_pending"
                  ) {
                     setrepayment_view_pending(true);
                  }
                  if (
                     option.key === "repayment_view_paid"
                  ) {
                     setrepayment_view_paid(true);
                  }
                  if (
                     option.key === "roi_paid_view"
                  ) {
                     setroi_paid_view(true);
                  }
                  if (
                     option.key === "view_loan_application" ||
                     option.key === "add_loan_application" ||
                     option.key === "manage_loan_application"
                  ) {
                     setview_loan_application(true);
                  }
                  if (
                     option.key === "view_loan_status" ||
                     option.key === "add_loan_status" ||
                     option.key === "edit_loan_status" ||
                     option.key === "delete_loan_status"
                  ) {
                     setview_loan_status(true);
                  }
               });
            }

         }
      }
   }

   useEffect(() => {
      handlePermissionAPI()

      // GetPermissions(employer.employer_token)
   }, []);

   return (
      <>
         <div className={header ? "app-header header sticky app_header_res" : "app-header header sticky"}>
            <div className="container-fluid main-container">
               <div className="d-flex">
                  {/* <div className="app-sidebar__toggle d-flex align-items-center" data-bs-toggle="sidebar"> */}
                  <div className="app-sidebar__toggle d-flex align-items-center">
                     <Link className="open-toggle" to="" onClick={() => ToggleHeader()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="feather feather-align-left header-icon" width="24" height="24" viewBox="0 0 24 24">
                           <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                        </svg>
                     </Link>
                  </div>
                  <Link className="logo-horizontal " to={props.prefix}>
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img desktop-lgo" alt="logo" />
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img dark-logo" alt="logo" />
                  </Link>
                  <div className="d-flex order-lg-2 ms-auto main-header-end">
                     <div className="navbar navbar-expand-lg navbar-collapse responsive-navbar p-0">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
                           <div className='text-primary' style={{ marginTop: 'auto' }}><h6 >{lendername}</h6> <div><h6 >{lendermobile}</h6></div></div>

                           <div className="d-flex order-lg-2">
                              <div className="dropdown profile-dropdown d-flex">
                                 <Link to={props.prefix} className="nav-link pe-0 leading-none" data-bs-toggle="dropdown">
                                    <span className="header-avatar1">
                                       {profileData.logo ?
                                          <img className="avatar avatar-md brround" src={'https://thirdroc1.s3.ap-southeast-2.amazonaws.com/logo/' + profileData.logo} />
                                          :
                                          <img src={process.env.PUBLIC_URL + '/assets/img/images.png'} alt="img" className="avatar avatar-md brround" />
                                       }
                                    </span>
                                 </Link>
                                 <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow animated">
                                    <Link className="dropdown-item d-flex" to={props.prefix} onClick={() => logOut()}>
                                       <svg className="header-icon me-2" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"> <g> <rect fill="none" height="24" width="24"></rect> </g> <g> <path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"> </path> </g> </svg> <div className="fs-13">Sign Out</div>
                                    </Link> </div>
                              </div>
                           </div>


                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="sticky is-expanded">
            <div className="app-sidebar__overlay active" data-bs-toggle="sidebar"></div>
            <aside className={header ? "app-sidebar open ps ps--active-y res_aside" : "app-sidebar open ps ps--active-y"}>
               <div className={header ? "app-sidebar__logo res_aside" : "app-sidebar__logo"}>
                  <Link className="header-brand" /* to={props.prefix} */>
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img desktop-lgo" alt="logo" />
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img mobile-logo" alt="Azea logo" />
                  </Link>
               </div>
               <div className="main-sidemenu is-expanded">
                  <div className="slide-left disabled active is-expanded" id="slide-left">
                     <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
                     </svg>
                  </div>

                  <ul className="side-menu app-sidebar3 open" style={{ marginRight: "0px" }}>
                     {/* <li className="side-item side-item-category">Main</li> */}
                     <li className="slide is-expanded">
                        <Link style={{ "color": pathname == '/lender/dashboard' ? "#0f2f82" : " " }} className={props.locationName == (props.prefix + '/dashboard' || props.prefix) ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/dashboard'}>
                           <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path>
                           </svg>
                           <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none" : "side-menu__label"}>Dashboard</span>
                        </Link>
                     </li>
                     {/* <ul className="side-menu app-sidebar3 open" style={{ marginRight: "0px" }}>
                     <li className="slide is-expanded">                                        
                        <Link className={props.locationName == props.prefix + '/dashboard' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/dashboard'}>
                           <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path>
                           </svg>
                           <span className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Dashboard</span>
                        </Link>
                     </li> */}
                     {(user_type == 'lenders' || newemployee_view || existingemployee_view) && (<li className="slide is-expanded">
                        <i className={header ? "fa-regular fas fa-users fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fas fa-users fa-fw side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { Borrower() }}
                           style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Borrower
                           <i className={submenuborrower ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                        <div className="dropdown-containerborrower mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                           {(newemployee_view || user_type == 'lenders') && <Link style={{ "color": pathname == '/lender/newborrower' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/newborrower' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/newborrower'} >
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>New</span>
                           </Link>}
                           {(existingemployee_view || user_type == 'lenders') && <Link style={{ "color": pathname == '/lender/employee' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/employee' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/employee'}>
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Existing</span>
                           </Link>}
                        </div>
                     </li>)}
                     {(user_type == 'lenders' || merchant_pending_view || merchant_paid_view) && (<li className="slide is-expanded">
                        <i className={header ? "fa-regular fa-handshake fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-handshake fa-fw side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenu() }}
                           style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Pay to Merchant
                           <i className={settinngSubmenu ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                        <div className="dropdown-container mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                           {(merchant_pending_view || user_type == 'lenders') && <Link style={{ "color": pathname == '/lender/pendingsettlement' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/pendingsettlement' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/pendingsettlement'}>
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}> Pending</span>
                           </Link>}
                           {(merchant_paid_view || user_type == 'lenders') && <Link style={{ "color": pathname == '/lender/merchanttransaction' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/merchanttransaction' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/merchanttransaction'}>
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Paid</span></Link>}
                        </div>
                     </li>)}
                     {(user_type == "lenders" || thirdroc_paid_view || thirdroc_pending_view) && (<li className="slide is-expanded">
                        <i className={header ? "fas fa-file-alt	 fa-fw side-menu__icon fs-18 res_icon" : "fas fa-file-alt	 fa-fw side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { paytothirdroc() }}
                           style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Pay to ThirdRoc
                           <i className={settinngSubmenuthirdroc ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                        <div className="dropdown-containerthird mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                           {(thirdroc_pending_view || user_type == 'lenders') && <Link style={{ "color": pathname == '/lender/thirdrocsettlement' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/thirdrocsettlement' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/thirdrocsettlement'}>
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Pending</span>
                           </Link>}
                           {(thirdroc_paid_view || user_type == 'lenders') && <Link style={{ "color": pathname == '/lender/thirdroctransaction' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/thirdroctransaction' ? "side-menu__item active" : "side-menu__item"}
                              to={prefix + '/thirdroctransaction'}     >
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none" : "side-menu__label"}>Paid</span></Link>}
                        </div>
                     </li>)}
                     {(user_type == "lenders" || repayment_view_pending || repayment_view_paid) && (<li className="slide is-expanded">
                        <i className={header ? "fas fa-file-alt fa-fw side-menu__icon fs-18 res_icon" : "fas fa-file-alt	 fa-fw side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { paytoEMI() }}
                           style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Repayments
                           <i className={settinngSubmenuEMI ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                        <div className="dropdown-containerEMI mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                           {(repayment_view_pending || user_type == 'lenders') && <Link style={{ "color": pathname == '/lender/pending_repayment' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/pending_repayment' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/pending_repayment'}>
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Pending</span>
                           </Link>}
                           {(repayment_view_paid || user_type == 'lenders') && <Link style={{ "color": pathname == '/lender/paid_repayment' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/paid_repayment' ? "side-menu__item active" : "side-menu__item"}
                              to={prefix + '/paid_repayment'}  > <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none" : "side-menu__label"}>Paid</span></Link>}
                        </div>
                     </li>)}
                     {/* <li className="slide is-expanded">
                        <Link  style={{ "color":pathname == '/lender/settlement' ? "#0f2f82":" "}}  className={props.locationName == prefix + '/settlement' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/settlement'}>
                           <i className={header ? "fa-regular fa-handshake fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-handshake fa-fw side-menu__icon fs-18"}></i>
                           <span className={header ? "side-menu__label" : "side-menu__label settlement"}>Settlement</span>
                        </Link>
                     </li> */}
                     {(user_type == "lenders") && <li className="slide is-expanded">
                        <i className={header ? "fa-solid fa-user-lock side-menu__icon fs-18 res_icon" : "fa-solid fa-user-lock side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { RolesandPermissions() }}
                           style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Role & Permission
                           <i className={rolepermission ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                        <div className="dropdown-containerRole mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                           <Link style={{ "color": pathname == '/lender/manage_role' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/manage_role' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/manage_role'}>
                              <span className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Manage Role</span>
                           </Link>
                           <Link style={{ "color": pathname == '/lender/manage_user' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/manage_user' ? "side-menu__item active" : "side-menu__item"}
                              to={prefix + '/manage_user'}  > <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Manage User</span></Link>
                        </div>
                     </li>}
                     {(user_type == "lenders" || roi_paid_view) && <li className="slide is-expanded">
                        <Link style={{ "color": pathname == '/lender/roi' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/roi' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/roi'}>
                           <i className={header ? "fa-solid fa-percent fa-fw side-menu__icon fs-18 res_icon" : "fa-solid fa-percent fa-fw side-menu__icon fs-18"}></i>
                           <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>ROI</span>
                        </Link>
                     </li>}
                     {/* {(user_type == "lenders" )&&<li className="slide is-expanded">
                        <Link style={{ "color": pathname == '/lender/manage_loan' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/manage_loan' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/manage_loan'}>
                           <i className={header ? "fa-solid fa-percent fa-fw side-menu__icon fs-18 res_icon" : "fa-solid fa-percent fa-fw side-menu__icon fs-18"}></i>
                           <span  onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Manage Loan</span>
                        </Link>
                     </li> } */}
                     {(user_type == "lenders" || view_loan_application || view_loan_status) && (<li className="slide is-expanded">
                        <i className={header ? "fa-regular fa-handshake fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-handshake fa-fw side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenuloan() }}
                           style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>EWA Advance
                           <i className={settingSubmenuloan ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                        <div className="dropdown-containerloan mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                           {(user_type == "lenders" || view_loan_application) && <Link style={{ "color": pathname == '/lender/manage_ewa_advance' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/manage_ewa_advance' ? "side-menu__item active" : "side-menu__item"}
                              to={prefix + '/manage_ewa_advance'}  ><span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Manage Application</span></Link>}
                           {/* {(user_type == "lenders" || view_loan_status) && <Link style={{ "color": pathname == '/lender/manage_status' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/manage_status' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/manage_status'}  ><span onClick={handlePermissionAPI} className='side-menu__label' >Manage Status</span></Link>} */}
                        </div>
                     </li>)}
                     {/* <li className="slide is-expanded">
                        <i className={header ? "fa-regular fa-handshake fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-handshake fa-fw side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { ManageInvestment() }}
                           style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Manage <br />Investment
                           <i className={setManageinvestment ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                        <div className="dropdown-containerinvestment mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                           <Link style={{ "color": pathname == '/lender/view_investment' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/view_investment' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/view_investment'}  ><span onClick={handlePermissionAPI} className='side-menu__label' >View Investment</span></Link>

                        </div>
                     </li> */}
                     {(user_type == "lenders") && <li className="slide is-expanded">
                        <i className={header ? "fa-solid fa-user-lock side-menu__icon fs-18 res_icon" : "fa-solid fa-user-lock side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { ManageInvestment() }}
                           style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Manage Investment
                           <i className={manageinvestment ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 4 }}></i></span>
                        <div className="dropdown-containerinvestment mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                           <Link style={{ "color": pathname == '/lender/view_investment' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/view_investment' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/view_investment'}>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>View Investment</span>
                           </Link>
                           {/* <Link style={{ "color": pathname == '/lender/manage_user' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/manage_user' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/manage_user'}  > <span className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Manage User</span></Link> */}
                        </div>
                     </li>}
                     {/* <li className="slide is-expanded" >
                        <Link className={props.locationName == props.prefix + '/profile' ? "side-menu__item active" : "side-menu__item"}  to={props.prefix + '/profile'} >
                           <i className={header ? "fa-solid fa-percent fa-fw side-menu__icon fs-18 res_icon" : "fa-solid fa-percent fa-fw side-menu__icon fs-18"}></i>
                           <span className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>ROI</span>
                        </Link>
                     </li> */}

                     <li className="slide is-expanded">
                        <Link style={{ "color": pathname == '/lender/profile' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/profile' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/profile'}>
                           <i className={header ? "fa-regular fa-address-card fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-address-card fa-fw side-menu__icon fs-18"}></i>
                           <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none " : "side-menu__label "}>Profile</span>
                        </Link>
                     </li>
                     <li className="slide is-expanded logout1">
                        <Link style={{ "color": pathname == '/lender/profile' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/profile' ? "side-menu__item active" : "side-menu__item"}
                           to={prefix} onClick={() => logOut()}>
                           <i className={header ? "fa-regular fas fa-sign-out fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fas fa-sign-out fa-fw side-menu__icon fs-18"}></i>
                           <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none " : "side-menu__label "}>Logout</span>
                        </Link>
                     </li>
                     <li className="slide is-expanded logout" >
                        <Link className="dropdown-item d-flex" to={prefix} onClick={() => logOut()}>
                           <svg className=" me-2" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24"
                              height="20" viewBox="0 0 24 24" width="20"> <g> <rect fill="none" height="20" width="20"></rect> </g> <g>
                                 <path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"> </path>
                              </g> </svg> <div className="fs-13">Sign Out</div>
                        </Link>
                     </li>
                  </ul>
                  <div className="slide-right" id="slide-right">
                     <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                     </svg>
                  </div>
               </div>
               <div className="ps__rail-x" style={{ left: "0px", bottom: "-300px" }}>
                  <div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}></div>
               </div>
               <div className="ps__rail-y" style={{ top: "300px", height: "657px", right: "0px" }}>
                  <div className="ps__thumb-y" tabIndex="0" style={{ top: "204px", height: "446px" }}></div>
               </div>
            </aside>
         </div>
      </>
   )
}

export default Header;

