import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import toast from 'react-hot-toast';
import PaginationComponent from '../../../PaginationComponent';
import Moment from 'react-moment';
import { DeleteEmployees, GetPermission, getApi } from '../../service/lenderService';
import Loader from '../../../components/Loader/loader';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import Search from '../../../components/Search/Search';
const prefix = process.env.REACT_APP_LENDER_PRIFIX;

const Newborrower = (props) => {

    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [statusdrop, setStatusdrop] = useState('')
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const navigate = useNavigate();
    const [loader, setloader] = useState(true)
    const [ValidPagination, setValidPagination] = useState(false)

    const lender = JSON.parse(localStorage.getItem("lender"));
    const getEmployeesData = async () => {
        const response = await getApi(`/all-submit-form-employee`, { filter: "Unassigned", search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
        if (response.status) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.allEmployee)
                setloader(false)
            }, 2000);

            setloader(true)
        }
        else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        }
        else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        getEmployeesData()
    }, [search, pagesCount, statusdrop, sortedBy, orderBy, ValidPagination]);

    const [permission_data, setPermission_data] = useState([])

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            console.log('response', response);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }
    const user_type = lender.user_type
    useEffect(() => {
        if (user_type == "SUB-LENDERS") {
            GetPermissions(token);
        }
    }, []);
    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);
    } else {

    }
    const [new_employee_view, setnew_employee_view] = useState(false)
    const [new_employee_limit, setnew_employee_limit] = useState(false)

    useEffect(() => {
        if (role) {
            const options = role;
            console.log(options)
            options.length > 0 && options.forEach((option) => {
                if (option.key == "new_employee_view") {
                    setnew_employee_view(true);
                }
                if (option.key == "new_employee_limit") {
                    setnew_employee_limit(true);
                }

            });
        }
    }, [role]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    return (
        <>

            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                                <h6 className="page-title mb-0 text-primary">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a>Borrower</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">New Borrower </li>
                                        </ol>
                                    </nav>
                                </h6>
                            </div>

                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <Link to="" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Borrower</Link> */}
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter} 
                                                  onChange={(e) => handleChangeQueryBuilder(e.target.value)} >
                                                    <option>Select Status</option>
                                                    <option value="all task" >All Status</option>
                                                    <option value="Assigned"  >Assigned</option>
                                                    <option value="Unassigned" >Unassigned</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Incomplete Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0"><option>Select Status</option></select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                {/* <div className="form-group">
                                    <label className="form-label">Loan Type</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name="loan_type"  value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)} >
                                                    <option>Select Loan</option>
                                                    <option value="1">BNPL</option>
                                                    <option value="2">Digital Lending</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Select Date</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <DateRangePicker onApply={handleCallback} onCancel={handleCallbackOnCancel} initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                                    <input placeholder="Search By Date" className="form-control fc-datepicker hasDatepicker" type="text" defaultValue="" />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12"></div>
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={() => setpagesCount(1)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        {/* <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="card">
                                    <div className="card-header br-0">
                                        <h3 className="card-title"> Cases Report {totalItems ? '(' + totalItems + ')' : ''} </h3> */}
                        {/* <div className="card-options"> 
							    <Link to="" className="btn btn-sm btn-outline-primary mx-sm-2"><i className="fa fa-file-upload"></i> Upload Cases</Link> 
								<Link to="" className="btn btn-sm btn-primary "><i className="fa fa-file-upload"></i> Update Cases</Link> 
							</div>  */}
                        {/* </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        {/* <th className="font-weight-bold">Country {sortedBy =="name" && orderBy==="desc" ? <i className='fas fa-long-arrow-alt-up' style={{fontSize : 10}} onClick={()=>{sortChange('name')}} ></i> :<i className='fas fa-long-arrow-alt-down' style={{fontSize : 10}} onClick={()=>{sortChange('name')}}></i>}</th> */}
                                                        <th className="font-weight-bold">Borrower Name {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Employer Name {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>}</th>
                                                        <th className="font-weight-bold">BNPL Status  {sortedBy == "bnpl_status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('bnpl_status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('bnpl_status') }}></i>}</th>
                                                        {/* <th className="font-weight-bold">Available Limit {sortedBy == "available_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('available_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('available_limit') }}></i>}</th>
                                                        <th className="font-weight-bold">Used Limit {sortedBy == "used_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('used_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('used_limit') }}></i>}</th> */}
                                                        <th className="font-weight-bold">Created Date  {sortedBy == "createdAt" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }}></i>}</th>
                                                        {/* <th className="font-weight-bold">Status  {sortedBy =="customer_id" && orderBy==="desc" ? <i className='fas fa-long-arrow-alt-up' style={{fontSize : 10}} onClick={()=>{sortChange('customer_id')}} ></i> :<i className='fas fa-long-arrow-alt-down' style={{fontSize : 10}} onClick={()=>{sortChange('customer_id')}}></i>}</th> */}
                                                        {(new_employee_limit || user_type == 'lenders') && <th className="font-weight-bold">Action</th>}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td />
                                                    <Loader />
                                                </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { bnpl_status, customer_id, mobile_number, first_name, personal_email, created_at, status, available_limit, total_limit, used_limit, company_name, name, employer_mobile, employer_id } = option;

                                                                return (

                                                                    <tr key={serial_num} >
                                                                        <td><Link className="text-primary" to={{ pathname: `${prefix}/employee_detail/${mobile_number}/New`, state: { mobile_number } }}>
                                                                            <span className="font-weight-normal1">{first_name ? first_name : ""}<br />{mobile_number}<br /><span style={{ textTransform: 'none' }}>{personal_email}</span></span></Link></td>
                                                                        <td><Link className="text-primary text-primary" to={{ pathname: `${prefix}/employer_detail/${employer_id}/New`, state: { employer_id } }}><span className="font-weight-normal1">{company_name ? company_name : ""}<br />{employer_mobile}</span></Link></td>
                                                                        <td><span className="font-weight-normal1">{option.bnpl_status ? bnpl_status : "--"}</span></td>

                                                                        {/* <td><span className="font-weight-normal1">{option.total_limit ? "$" + total_limit : 0.00}</span></td>
                                                                        <td><span className="font-weight-normal1">{option.available_limit ? "$" + available_limit : 0.00}</span></td> */}
                                                                        {/* <td><Link className="text-primary" to={{pathname: `${prefix}/usedlimitdetails/${customer_id}/New`, state: {customer_id}}}>
                                                                            <span className="font-weight-normal1" >{option.used_limit ? "$" + used_limit : 0.00}</span>
                                                                            </Link>
                                                                        </td> */}
                                                                        <td><span className="font-weight-normal1">{/* <Moment format="YYYY-MM-DD "> */}{TimeZone(created_at).timeZone}</span></td>
                                                                        {(new_employee_limit || user_type == 'lenders') && (<td><Link className='btn btn-primary btn-pill' to={prefix + `/employee_setlimit/${customer_id}/New`}> <span className="font-weight-normal1">Set Limit </span></Link></td>)}
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No New Borrower Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>
                                                }</table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => { setpagesCount(e) }}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => { setpagesCount(e) }}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default Newborrower;
